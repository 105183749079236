import React,{Component} from 'react';
import fire from './../fire';
import Mobnav from './nav/mobnav'
import Parent from './body/Parent'
import Login from './auth/Login'
export default class Menu extends Component{
	constructor(props){
		super(props)
		this.state= {
			productid : []
		}
	}

	getmenu = () =>{
		var db= fire.firestore();
		var args = this.props.match.params;
		var menu = db.collection('products').where('sellerid', '==', args.id);
			menu.get().then(querySnapshot => {
				let docs = querySnapshot.docs;
				console.log(docs)
				const productid = docs.map(obj => obj.id)
				this.setState({
					productid: productid
				});
				// productid.map(tid => {
				// 	this.getDetails(tid);
				// })
         });
	}

	
	
	componentWillMount() {
		// this.getmenu();
	}
	render() {
		const args = this.props.match.params;
		return (
			<div id="main">
			
			<Mobnav />
			
			<Parent sellerid={args.id} />
			
			</div>
		);
	}
}