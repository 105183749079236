import React,{Component} from 'react';
import fire from './../../fire';
export default class Parent extends Component{
	constructor(props){
		super(props)
		this.state={
			citems :[]
		}
	}
	
	render() {
		const data = this.props.data;
		return (
			<div className="col-md-3  b1-hidemobile"  >
			<div className=" w3-white just" id="leftmenu" style={{height : '95vh' , overflow : 'auto', marginTop : '3vh'}}>
			<header className="b1-dcolor">
			<h2 className="b1-align">Categories</h2>
			</header>
			<ul className="w3-ul">
			{data.catnames.map((name,id)=>
				<Listitems key ={id} close={this.props.close} data={data} id={id} name={name} change={this.props.change}/>
				)}
				</ul>
			</div>
              </div>
			
		);
	}
}


class Listitems extends Component{
	constructor(props){
		super(props)
	}
  close =()=>{
     this.props.close(this.props.id)
	}

	change =()=>{
     this.props.change(this.props.id)
	}

	render() {
		const data = this.props.data;
		return (
			
			
			<li  onClick={data.clicked && this.props.id == data.catid ? this.close : this.change} 
				className={data.clicked && this.props.id == data.catid ? "w3-blue w3-hover-red":"w3-hover-teal"}>
				{this.props.name}
				<span className={data.clicked && this.props.id== data.catid? "w3-right fa fa-close":"w3-right fa fa-angle-right"}>
				</span>
				</li>

			
		);
	}
}