import React,{Component} from 'react';
import fire from './../../fire';
import Addtocart from './Addtocart'
var db= fire.firestore();
export default class Singlepro extends Component{
	constructor(props){
		super(props)
		this.state ={
			quantity: 1,
			single:true
		}
	}
	getDetails= () =>{
		var db = fire.firestore();
		var tref = db.collection('products').doc(this.props.productid);
		var tdetail = tref.get()
		              .then(res =>{
		              	var resd= res.data();
		              	this.setState({
		              		name: resd.name ,
		              		single : resd.single,
		              		price : resd.price,
		              		half : resd.half,
		              		full : resd.full,
		              		veg : resd.veg ,
		              		fprice : resd.single ? resd.price : resd.half
		              	});
		              })
		             
	}

	fprice = price =>{
		this.setState({
			fprice: price 
		});
	}
	componentDidMount() {
		// t his.getDetails();
	}
	render() {
			 const {data} = this.props
			 const {price,name,veg,img} = data
		return (
			<div className="w3-panel w3-half b1-prcard" >
			<div className="w3-card-2 w3-white w3-hover-shadow  b1-product b1-prcard" style={{height:330}} >
			<div>
				<div style={{display:'flex',justifyContent:'center',height:220,width:'100%',backgroundColor:'teal'}}>
				    <img src={img} style={{flex:1,width:'100%',}}/>
				</div>
			
			{/* <h3 className="w3-right" style={{paddingTop:'0.5vh',marginRight:'1vw'}}>₹{100}</h3> */}
			<h1 className="w3-left b1-icon" style={{marginTop:-20,zIndex:333}} >
			{veg? <i className="fa fa-eercast w3-text-green" />
			: <i className="fa fa-eercast w3-text-red" />}
			</h1>
			 <h4 style={{margin:10}} ><b>{name.toUpperCase()}</b></h4>
			 
			</div>
			
			{/* {this.state.single? <Single price={this.state.price} pid={this.props.productid} veg={this.state.veg}/>
			:<Double half={this.state.half} full={this.state.full} fprice={this.fprice}
			 pid={this.props.productid} veg={this.state.veg}/>} */}
			
			</div>
			</div>
		);
	}
}



class Double extends Component{
	constructor(props){
		super(props)
		this.state={
			ishalf: true,
			single : false
		}

	}
	changebtn = () =>{
		const ishalf = !this.state.ishalf;
		this.setState({
			ishalf 
		});
		const price = ishalf? this.props.half : this.props.full;
		this.props.fprice(price)
	}

	checkadded = () =>{
		 var db= fire.firestore();
      fire.auth().onAuthStateChanged(user => {
          if (user) {
     
   db.collection('cart').where("uid","==",user.uid)
   .where("pid","==",this.props.pid)
   .where("active","==",true)
   .where("single","==",false)
   .where("half","==",this.state.ishalf)
   .limit(1)
   .get()
      	.then(res =>{
      		
      		if (res.docs.length > 0) {

      		this.setState({
      			added: true
      		});
      		
      		db.collection('cart').doc(res.docs[0].id).onSnapshot(data =>{
      			this.setState({
      				added: false 
      			});
      		}, err => {
        console.log(`Encountered error: ${err}`);
          });
      	}
      		
      	})
      	.catch(err =>{
      		console.log('error',err)
      	})
       }
     })
	}
	componentWillMount() {
		//this.checkadded();
	}
	render() {
		const hbtn =<Addtocart single={false} ishalf={true} price={this.props.half} pid={this.props.pid}/>
		const fbtn=<Addtocart single={false} ishalf={false} price={this.props.full} pid={this.props.pid}/>
			const price=this.state.ishalf ? this.props.half : this.props.full
			
		return (
			<div className="" style={{padding : '6px'}}>
			
			<div className="w3-bar">
			<button onClick={this.changebtn} className={this.state.ishalf?"w3-teal w3-border w3-button":" w3-border w3-button"}>Half</button>
			<button onClick={this.changebtn} className={this.state.ishalf?" w3-border w3-button":"w3-teal w3-border w3-button"}>Full</button>
			</div>
			
			<div className={this.state.ishalf?"w3-hide":""}>{fbtn}</div>
			<div className={this.state.ishalf?"":"w3-hide"}>{hbtn}</div>
			
			</div>
		);
	}
}

class Single extends Component{
	constructor(props){
		super(props)
		this.state = {
			single : true,
			ishalf : false
		}
	}
	
	render() {
		return (
			<div className="" style={{padding : '4px'}}>
			
			<Addtocart single={this.state.single} price={this.props.price} pid={this.props.pid} ishalf={this.state.ishalf}/>
			
			</div>
		);
	}
}

