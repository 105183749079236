import React,{Component} from 'react'
import fire from './../fire'

var db=fire.firestore()
export default class Orditem extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
	getitems = () =>{
		db.collection('cart').doc(this.props.data).get()
		.then(res=>{
			if (res.exists) {
				this.setState(res.data());
			db.collection('products').doc(res.data().pid).get()
			.then(data=>{
				if (data.exists) {
					this.setState({
						name: data.data().name 
					});
				}
			})
			}
		})
	}
	componentDidMount() {
	
		if (this.props.data) {
			this.getitems()
		}
	}
	render() {
		return (
			<div className="w3-panel">
			<div className="col-md-4"><h4>{this.state.name}</h4></div>
			<div className="col-md-3">
			<h4>
			<span className="w3-left">{this.state.quantity}</span>
			<span className="w3-right">{this.state.total}</span>
			</h4>
			</div>
			</div>
		);
	}
}