import React , {Component} from 'react';
import fire from './../fire'
import Addproduct from './Addproduct'
import Allitems from './Allitems'
import Orders from './Orders'
import History from './History'
import Checkpartner from './functions/Checkpartner'
var http = require('http');
var urlencode = require('urlencode');

export default class Dashboard extends Component{
	
	constructor(props){
		super(props)
		this.state ={orders:true,
		             items : false,
		            new:false,
		            history:false}
	}
	click = e =>{
		this.setState({orders:false,
		             items : false,
		            new:false,
		            history:false});
         this.setState({
         	[e.target.value]: true
         });
	}

	componentDidMount() {
		// Checkpartner();
	}
	render() {
		const sid = this.props.sid ? this.props.sid : '';

		
		return (
			<div >
			<div className="w3-bar w3-white w3-card w3-padding" 
			style={{position : 'fixed',top :'0',zIndex:'2'}}>
			<button className="btn btn-primary" value="orders" onClick={this.click}>Orders </button>
			<button className="btn btn-success" value="items" onClick={this.click}
			 style={{marginLeft : '7px'}}>All Items </button>
			<button className="btn btn-danger" value="new" onClick={this.click}
			style={{marginLeft : '7px'}}>Add New </button>

			<button className="btn w3-teal" value="history" onClick={this.click}
			style={{marginLeft : '7px'}}>History </button>
			
			<br/>
			</div>
			<div className="w3-container" style={{marginTop : '10vh'}}>
			<span className={this.state.orders? "" : "w3-hide"} > <Orders sellerid={sid}/></span> 
			<span className={this.state.items? "" : "w3-hide"} > <Allitems sellerid={sid}/> </span> 
			<span className={this.state.new? "" : "w3-hide"} > <Addproduct sellerid={sid}/></span> 
			<span className={this.state.history? "" : "w3-hide"} > <History sellerid={sid}/></span> 
			</div>
			</div>
		);
	}
}



class Sendsms extends Component{
	constructor(props){
		super(props)
	}


sendsms = () =>{
		var msg = urlencode('Hey Binova, your order of total 305 is received. You will be contacted soon for the confirmation of the order.');
var toNumber = '919582852710';
var username = 'novagame64@gmail.com';
var hash = '94098f88947d7afdcf99d62015e559b9ebc98e810a6fd7c85fcbd26a29cc22b7'; // The hash key could be found under Help->All Documentation->Your hash key. Alternatively you can use your Textlocal password in plain text.
var sender = 'HEYHUN';
var data = 'username=' + username + '&hash=' + hash + '&sender=' + sender + '&numbers=' + toNumber + '&message=' + msg;
var options = {
  host: 'api.textlocal.in', path: '/send?' + data
};
 var callback =function (response) {
  var str = '';//another chunk of data has been recieved, so append it to `str`
  response.on('data', function (chunk) {
    str += chunk;
  });//the whole response has been recieved, so we just print it out here
  response.on('end', function () {
    console.log(str);
  });
}
http.request(options, callback).end();
	}
	render() {
		return (
			<div>
			<button className="btn btn-warning" onClick={this.sendsms} > Send</button>
			</div>
		);
	}
}


