import fire from './../../fire'

var db=fire.firestore()


var Checkpartner = () =>{
    fire.auth().onAuthStateChanged(user =>{
    	if(user){
 db.collection('partners').where("uid","==",user.uid).limit(1).get()
 .then(res =>{
 	if (res.size == 1) {
 		if (res.docs[0].exists) {
 			return res.docs[0].data().sid 
 		}else{
   	window.location= "/"
   }
 	}
    })
   }else{
   	window.location = "/"
   }
})
}

export default Checkpartner;