import React , {Component} from 'react';
import fire from './../fire'

var db = fire.firestore()

export default class ApporderHistory extends Component {
 
  constructor(props) {
    super(props)
    this.state={hasOrder:false}
  }

  getOrders = () =>{
  	 db.collection('newOrders')
  	.where("sellerId","==",this.props.sellerid)
  	.where("active","==",false)
  	.where("placed","==",true)
  	.onSnapshot(res=>{
  		if (res.size>0) {
  			//this.setState({docs:res.docs,hasOrder:true})
  			console.log('size',res.size)
  			let total = 0;
  			let topay =0;
  			let count =0;
  			res.docs.map(doc=>{
  				let data = doc.data()
  				
  					total += data.total
  					topay += data.topay
  					count++
  					console.log('count',count)
  				
  			})

  			if (count+1 > res.size) {
  				this.setState({total,topay})
  				console.log("ho gaya --------------------------------------------------------------")
  			}

  		}else{
  			this.setState({hasOrder:false})
  		}
  	})
  }

  componentDidMount() {
  	// this.getOrders()
  }
  render() {
    return (
      <div>
       < h2>Total: {this.state.total?this.state.total :'nahi hai bhai abhi'}</h2>
       < h2>Paid: {this.state.topay? this.state.topay:'nahi hai bhai abhi'}</h2>
      </div>
    );
  }
}