import React,{Component} from 'react';
import { Route, Redirect } from 'react-router'
//const cookies = new Cookies();

export default class Landing extends Component{
	constructor(props){
		super(props)
	}
   
	componentDidMount() {
		
	}

	takeadd = () =>{
		 //document.getElementById('changeloc').style.display='block'
	}
	render() {
		return (
			<div className="b1-over w3-white ">
			

			<div className="col-md-6 col-md-offset-3 w3-padding b1-align" style={{marginTop:'25vh'}}>
			<br/>
			
			 <h1 className="w3-text-teal  b1-brand" style={{fontSize:60}} >HeyHungry</h1>
             <a href="https://play.google.com/store/apps/details?id=com.heyhungry.heyhungry">
             <img style={{height:'100px'}}
             src="https://firebasestorage.googleapis.com/v0/b/heyhungry-1245.appspot.com/o/public%2Fen_badge_web_generic.png?alt=media&token=2e8345d1-be9e-4e06-8161-b686dc8a8a58"/>
             </a>
			</div>
             
			</div>
		);
	}
}

