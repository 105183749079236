import React,{Component} from 'react';
import Sidebar from './Sidebar'
import fire from './../../fire';
import auth from './../auth/Auth'
import Login from './../auth/Login'
import Loginmodel from './../auth/Loginmodel'
import Changeloc from './Changeloc'
import Locateme from './../location/Locateme'

import { Route, Redirect,Link } from 'react-router-dom'
  import Cookies from 'universal-cookie';


const cookies = new Cookies();
var db = fire.firestore();

export default class Mobnav extends Component{
	constructor(props){
		super(props)
		this.state ={cart:0,}
	}
  componentWillUnmount() {
    this.setState({
      mounted: false 
    });
  }
  checkopen = () =>{
   if(cookies.get('seller') ){
    var id = cookies.get('seller').id
     db.collection('sellers').doc(id).onSnapshot(res=>{
      if (res.exists && this.state.mounted) {
        
        var open = res.data().open
        this.setState({
          closed: !open,
          msg : res.data().message
        });
      }
     })
   }
  }
	componentDidMount() {
    this.setState({
      mounted: true 
    });
    this.getAdd()
   //Checking if the outlet is in service or not
    this.checkopen()
		fire.auth().onAuthStateChanged(user => {
          if (user) {
          
            db.collection('udetails').doc(user.uid).onSnapshot(doc => {
              if (doc.exists) {
              this.setState({
              	cart: doc.data().cart
              });
              }

             
          }, err => {
        console.log(`Encountered error: ${err}`);
          });
            this.setState({
            	uid: user.uid,
            	log : true 
            });
           
          } else {
            this.setState({
            	log: false 
            });
          }
      });
	}

  check = () =>{
   
  }
  
   getAdd = () =>{
      var address = cookies.get('address') ? cookies.get('address').formatted_address : 'Select a location'
      this.setState({
        address 
      });
    }



    opennav = () =>{
       document.getElementById("mySidenav").style.width = "22em";
       document.getElementById("myOverlay").style.display = "block";
    }
    closenav = () =>{
       document.getElementById("mySidenav").style.width = "0px";
       document.getElementById("myOverlay").style.display = "none";
    }

    changeloc = () =>{
        document.getElementById("changeloc").style.display = "block";
    }
	render() {
		return (
			<div className="w3-row" >
       
      <Login/>
      <Loginmodel/>
      <Changeloc />
      <div id="mySidenav" className="b1-sidenav w3-card-4">
       <Sidebar close={this.closenav} />
    
      </div>
      {this.state.closed ? <div className="w3-overlay b1-align" style={{display:'block'}}>
              <div className="w3-panel w3-yellow w3-padding w3-card" style={{marginTop:'30vh'}}> 
              <h3><i className="fa fa-bell"></i> {this.state.msg} </h3>
               Different Location ? <Locateme/>
              </div></div>

              :'' }
       
       <div className="w3-overlay w3-animate-opacity" onClick={this.closenav}  id="myOverlay"></div>


        <div className=" b1-dcolor  w3-xlarge w3-top " id="mobnav" style={{maxHeight:'63px'}}>
        <Deskbar address={this.state.address} changeloc={this.changeloc} cart={this.state.cart} opennav={this.opennav}/>
         <Mobbar address={this.state.address} changeloc={this.changeloc} opennav={this.opennav}/>
       
   
  </div>
  </div>
  

		);
	}
}




class Mobbar extends Component{
  constructor(props){
    super(props)
  }

  render() {
    return (
      <div className="row b1-hidelarge" style={{paddingBottom :'0'}}>
       <div className="col-xs-2">
       <span className=" btn b1-dcolor w3-xlarge" onClick={this.props.opennav}>
        <i className="fa fa-bars" ></i></span>
       </div>

        <div className="col-xs-7 " style={{padding : '0'}}>
        <button className="btn w3-teal " style={{marginRight : '2vw',padding:'0'}} onClick={this.props.changeloc}>
        <span className="w3-padding">Change</span></button>
        <i className="fa fa-map-marker fa-1x"> </i><span className="w3-large"> Deliver at:</span>
        <h5 className="b1-ltdtxt " style={{marginTop : '-4px'}}>{this.props.address}</h5>
       </div>

      

        <div className="col-xs-3 w3-animate-opacity btn " >
       <span className="w3-right" >
        <Link to="/"> 
        <img src="https://firebasestorage.googleapis.com/v0/b/heyhungry-1245.appspot.com/o/public%2Flogo%2Fheylogoo2.png?alt=media&token=fc56a494-b4f4-482b-a7bf-e97a1ca28bd0"
        className="w3-image" style={{height : '50px'}} />
         </Link>
      </span>
     </div>
      </div>
    );
  }
}

 class Deskbar extends Component{
  constructor(props){
    super(props)
  }

  render() {
    return (
      <div className="row b1-hidemobile" style={{marginTop:'-4px'}}>
       <div className="col-md-2">
       <span className="  btn b1-dcolor w3-xlarge" onClick={this.props.opennav}>
        <i className="fa fa-bars" ></i></span>
       </div>
      <div className="col-md-5 " style={{padding : '0'}}>
        <button className="btn w3-teal" style={{marginRight : '2vw'}} onClick={this.props.changeloc} >
        <span className="w3-padding">Change</span></button>
        <i className="fa fa-map-marker fa-1x"> </i>
        <span className="w3-large w3-right"> Deliver at:
        <span className="b1-ltdtxt w3-small" >{this.props.address}</span>
        </span>
        
       </div>

       <div className="col-md-3 w3-padding">
       <Link to="/cart">
       <img src="https://firebasestorage.googleapis.com/v0/b/heyhungry-1245.appspot.com/o/public%2Flogo%2F839346_food_512x512.png?alt=media&token=036e2219-5395-425b-aa2f-6db0a421b161"
       className="w3-image w3-right" style={{height : '55px'}} />
       <h4 className="w3-right w3-text-red" style={{marginRight:'-32px',marginTop :'18px'}}>
       <b>{this.props.cart}</b></h4>
       </Link>
       </div>

        <div className="col-md-2 w3-animate-opacity btn " >
       <span className="w3-right">
       <Link to="/">
           <img src="https://firebasestorage.googleapis.com/v0/b/heyhungry-1245.appspot.com/o/public%2Flogo%2Fheylogoo2.png?alt=media&token=fc56a494-b4f4-482b-a7bf-e97a1ca28bd0"
        className="w3-image" style={{height : '50px'}} /></Link>
      </span>
     </div>
      
      </div>
    );
  }
}