import React , {Component} from 'react';
import fire from './../fire'
import Addproduct from './Addproduct'
import Allitems from './Allitems'
import Orders from './Orders'
import Apporders from './Apporders'
import History from './History'
import ApporderHistory from './ApporderHistory'
import Checkpartner from './functions/Checkpartner'

var http = require('http');
var urlencode = require('urlencode');

export default class Adashboard extends Component{
	
	constructor(props){
		super(props)
		this.state ={orders:false,
		             items : true,
		             new:false,
		            history:false,
		            Apphistory:false}
	}
	click = e =>{
		this.setState({orders:false,
		             items : false,
		            new:false,
		            history:false});
         this.setState({
         	[e.target.value]: true
         });
	}

	componentDidMount() {
		Checkpartner();
		//<Allitems sellerid={sid}/>
	}
	render() {
		const sid = this.props.sid ? this.props.sid : '';

		
		return (
			<div >
			<div className="w3-bar w3-white w3-card w3-padding" 
			style={{zIndex:'2'}}>
			<button className="btn btn-primary" value="orders" onClick={this.click}>Orders </button>
			<button className="btn btn-success" value="items" onClick={this.click}
			 style={{marginLeft : '7px'}}>AppOrders </button>
			<button className="btn btn-danger" value="new" onClick={this.click}
			style={{marginLeft : '7px'}}>Add New </button>

			<button className="btn w3-teal" value="history" onClick={this.click}
			style={{marginLeft : '7px'}}>History </button>

			<button className="btn w3-black" value="Apphistory" onClick={this.click}
			style={{marginLeft : '7px'}}>All Items </button>
			<br/>
			</div>
			<div className="w3-container" style={{marginTop : '10vh'}}>
			<span className={this.state.orders? "" : "w3-hide"} > <Orders sellerid={sid}/></span> 
			<span className={this.state.items? "" : "w3-hide"} > <Apporders sellerid={sid}/> </span> 
			<span className={this.state.new? "" : "w3-hide"} > <Addproduct sellerid={sid}/></span> 
			<span className={this.state.history? "" : "w3-hide"} > <History sellerid={sid}/></span> 
			<span className={this.state.Apphistory? "" : "w3-hide"} > <Allitems sellerid={sid}/></span> 
			
			</div>
			</div>
		);
	}
}


