import React,{Component} from 'react'
import fire from './../../fire';

var db = fire.firestore()
export default class Singleitem extends Component{
	constructor(props){
		super(props)
		this.state={}
	}
	getcart = () =>{
		db.collection('cart').doc(this.props.data).get()
		.then(res =>{
			var data = res.data();
			if(res.exists){
              db.collection('products').doc(data.pid).get()
              .then(ref =>{
              	if (ref.exists) {
              		this.setState({
              			name: ref.data().name,
              			qty : data.quantity,
              			total:data.total 
              		});
              	}
              })
              .catch(err =>{

              })
			}
		})
	}
	componentWillMount() {
		this.getcart()
	}
	render() {
		return (
			<div className="w3-panel  row">
			<div className=" col-xs-7">
			<h4>{this.state.name}</h4>
			</div>

			<div className=" col-xs-2 ">
			<h4 className="w3-right">{this.state.qty}</h4>
			</div>


			<div className=" col-xs-3">
			<h4 className="w3-right">₹{this.state.total}</h4>
			</div>
			</div>
		);
	}
}