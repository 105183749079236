import fire from './../../fire';

var db = fire.firestore()

export default function price(cartid,dprice){
	db.collection('cart').doc(cartid).onSnapshot(doc=>{
			if (doc.exists) {
				var diff = parseInt(doc.data().total) - parseInt(dprice)
				console.log('difference ' , diff)
				console.log('snap total ' , doc.data().total )
				console.log('price ' , dprice)
				
			}
		})
}