import React,{Component} from 'react'
import Mobnav from './mobnav'
import Cookies from 'universal-cookie';

const cookies = new Cookies;

export default class Notavlbl extends Component{
	constructor(props){
		super(props)
	}
	
	render() {
		return (
			<div >
			<Mobnav />
			<div className="col-md-6 col-md-offset-3 b1-align" style={{marginTop: '40vh'}}>
			<h2 className="w3-text-grey">
			 Sorry!!! We are not available near you. But will see you soon..</h2>

			 <h1 className="b1-brand"> Thanxx for visiting</h1>
			 </div>
			 </div>
		);
	}
}