import React,{Component} from 'react';
import fire,{ui} from './../../fire'
import * as firebase from 'firebase';
import Loginmodel from './Loginmodel'
import auth from './Auth'
import Cookies from 'universal-cookie';

const cookies = new Cookies();
  
 
    const uiConfig = {
          callbacks: {
          signInSuccess: function(currentUser, credential, redirectUrl) {
            return true;
          },
          uiShown: function() {
          
          }
        },
        signInSuccessUrl: cookies.get('seller') ? `/menu/${cookies.get('seller').id}`: '/',
        signInOptions: [
     
         firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          {
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      scopes: [
        'public_profile',
        'email'
        
      ],
  },
          {
      provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: 'image', // 'audio'
        size: 'invisible', // 'invisible' or 'compact'
        badge: 'bottomleft' //' bottomright' or 'inline' applies to invisible.
      },
      defaultCountry: 'IN', 
      defaultNationalNumber: '1234567890',
      
      loginHint: '+11234567890'
    }
          
        ],
        
        tosUrl: '/'
      };

       
export default class Login extends Component {
  constructor(props){
    super(props)
    this.state = {
                  log : true
                  };


  }
  
      componentDidMount() {
        this.initApp();
       
   }

      
   logout= () =>{
    firebase.auth().signOut().then(()=> {
  this.setState({
    log: false 
  });
  window.location.assign("/")
  console.log('msg')
  this.initlogin();
}).catch(function(error) {
 // console.log(error);
});
   
} 

   initApp =() => {
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
            // User is signed in.
            this.setState({
              log: true,
              name :user.displayName,
              photo : user.photoURL,
              uid : user.uid, 
            });
           
          } else {
             ui.start('#binova', uiConfig);
            this.setState({
              log: false 
            });
           
          }
        }, function(error) {
          console.log(error);
        });
         
      };
  render() {
  
    return (
      <div/>
    );
  }
}

