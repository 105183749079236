import React,{Component} from 'react'
import Mobnav from  './../nav/mobnav'
import fire from './../../fire'

var db = fire.firestore()

export default class Adressbook extends Component{
	constructor(props){
		super(props)
	}
	render() {
		return (
			<div>
			<Mobnav />
			<div className="container">
			<Alladd />
			</div>
			</div>
		);
	}
}



class Alladd extends Component{
	constructor(props){
		super(props)
		this.state ={address : [],
	                  loading : true}
	}
    getadd = () =>{
    	fire.auth().onAuthStateChanged(user => {
          if (user) {
          	db.collection(`udetails/${user.uid}/address`).get()
          	.then(res =>{
          		this.setState({
          			loading: false ,
          			uid : user.uid
          		});
          		if (res.size > 0) {
          			res.docs.map((data,id) =>{
          			          			var doc =data.data()
          			          			
          			          			var address = this.state.address
          			          			address.push(data.id)

          			          			this.setState({
          			          			 	address,
          			          			 	[data.id] : doc
          			          			 }); 
          			          			})
          		}
          		else{
          			this.setState({
          				noadd: true 
          			});
          		}
          	})
         }
     })
  }
    componentDidMount() {
    	this.getadd()
    }
	render() {
		 const comp = this.state.loading? <div className="b1-loader"></div> :
                 this.state.address.map((data,id) =>
                 	<span key={id}>
                <Singleadd uid={this.state.uid} aid={data} data={this.state[data]}/>
                
                </span>)

                  
		return (
			<div >
			<br/>
			<br/>
			<br/>
              {this.state.noadd ? 
              	<div className="col-md-8 col-md-offset-2 w3-card b1-dcolor b1-align w3-padding">
             <i className="fa fa-info-circle fa-2x"> There are no addresses added</i>
              </div> : comp }
                  
			</div>
		);
	}
}


class Singleadd extends Component{
	constructor(props){
		super(props)
		this.state = {exists : true}
	}

	delete = () =>{
		db.collection(`udetails/${this.props.uid}/address`).doc(this.props.aid).delete()
		.then(() =>{
			this.setState({
				exists: false 
			});
		})
	}

	render() {
		const data= this.props.data
		return (
			<div className={this.state.exists ? "col-md-6 col-md-offset-3 " : "w3-hide"}>
			<br/>
			<div className="w3-card w3-padding">
			<div className="w3-row w3-padding">
			<button onClick={this.delete}  className="btn btn-danger ">
			<i className="fa fa-delete">Delete</i>
			</button>
             </div>

			<div className="row">
			<div className="col-xs-4">
			<h5 className="w3-right"> House/Door No.</h5>
			</div>

            <div className="col-xs-8">
			<h5 className="w3-left">: {data.house} </h5>
			</div>
			</div>

			<div className="row">
			<div className="col-xs-4">
			<h5 className="w3-right"> Landmark </h5>
			</div>

            <div className="col-xs-8">
			<h5 className="w3-left">: {data.landmark} </h5>
			</div>
			</div>

			<div className="row">
			<div className="col-xs-4">
			<h3 className="w3-right"> <i className="fa fa-map-marker"></i> </h3>
			</div>

            <div className="col-xs-8">
			<h5 className="w3-left"> {data.f_address} </h5>
			</div>
			</div>



			</div>
			</div>
		);
	}
}