import React,{Component} from 'react';

import Category from './Category'
import Menuswipe from './../swipe/Menuswipe'
import Specialcat from './Specialcat'
import fire from './../../fire';
export default class Parent extends Component{
	constructor(props){
		super(props)
		this.state={
			citems :[]
		}
	}
	

	render() {
		return (
			<div className=" b1-parent" style={{height : '100%'}}>
			
			<Specialcat sellerid={this.props.sellerid} cartn={this.props.cartn}/>
			<Category sellerid={this.props.sellerid} cartn={this.props.cartn}/>
			
              </div>
			
		);
	}
}