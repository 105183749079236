import React,{Component} from 'react';
import fire from './../../fire';
import {Link} from 'react-router-dom'
import Cookies from 'universal-cookie';
import Udetails from './Udetails'
import Locateme from './../location/Locateme'

const cookies = new Cookies();
var db= fire.firestore()
export default class Sidebar extends Component{
  constructor(props){
    super(props)
    this.state ={log:false}
  }
  getuser = () =>{
    fire.auth().onAuthStateChanged(user => {
          if (user && this.state.mounted) {
            this.setState({
              log: true,
              uid : user.uid 
            });
          }
        
           
        })
          

  }
  login = () =>{
    document.getElementById("mySidenav").style.width = "0px";
       document.getElementById("myOverlay").style.display = "none";
    document.getElementById('id01').style.display='block'
  }

  logout = () =>{
   fire.auth().signOut().then(res =>{
   
    const url = cookies.get('seller') ? `/menu/${cookies.get('seller').id}` : "/";
      window.location.assign(url);
    })
  }

  checkprofile = (uid) =>{
    var dp = "https://i1.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?resize=256%2C256&quality=100"
          
            db.collection('udetails').doc(uid).get().then(res =>{
              if (res.exists) {
                document.getElementById('udetails').style.display='none'
                var data = res.data()
                if (this.state.mounted) {this.setState({name: data.name ,
                 photo : data.photo || dp 
                });}
                
              }
              else{
                document.getElementById('udetails').style.display='block'
              }
            }).catch(err=>{})
  }
  componentDidMount() {
    this.setState({
      mounted: true 
    });
    this.getuser()
   
  }
 componentWillUnmount() {
   this.setState({
      mounted: false 
    });
   
 }
  componentWillUpdate(nextProps, nextState) {
    if (nextState.log) {
      this.checkprofile(nextState.uid)
    }
  }
  render() {
     
    return (
      <div>
      <div className="w3-container b1-align b1-dcolor w3-padding">
      <button className="btn b1-dcolor w3-hover-text-yellow w3-right" onClick={this.props.close}>X</button>
      <img src={this.state.photo} className="w3-image w3-circle b1-profile"/>
      <h3 className="w3-text-white">{this.state.name}</h3>
      </div>
      <div className="w3-container">
      <ul className="w3-ul w3-hoverable">
    {this.state.log?  <div>
       <Singlemenu to="/cart" name="Cart" css="fa fa-cutlery w3-left"/>
       <Singlemenu to="/orders" name="Orders" css="fa fa-tags w3-left"/>
       <Singlemenu to="/address-book" name="Adress Book" css="fa fa-map-marker w3-left"/>
       </div>:''}
       <Changeloc/>
      </ul>
      <Udetails />
      <div className="col-md-6 col-md-offset-2 b1-align" style={{positon: 'fixed',
       top :this.state.log ? '20vh':'50vh'}}>
      <h3 className={this.state.log ? "w3-button w3-round-xxlarge b1-red1 w3-padding"
       : "w3-button w3-round-xxlarge b1-dcolor w3-padding"}
       onClick={this.state.log ? this.logout : this.login}>
     {this.state.log ? "LogOut" : "LogIn/SignUp"}
      </h3>
      </div>
      </div>
      </div>
    );
  }
}


class Singlemenu extends Component{
	constructor(props){
		super(props)
	}
	render() {
		return (
			
			<li>
       <Link to={this.props.to}>
       <button className="w3-button b1-button w3-xlarge ">
      <i className={this.props.css}> {this.props.name}</i>
      
       <i className="fa fa-angle-right w3-right"></i>
      </button>
      </Link>
       </li>
		);
	}
}


class Changeloc extends Component{
	constructor(props){
		super(props)
    this.state ={active : false}
	}
  toggle = () =>{
    var active = !this.state.active
    this.setState({
      active 
    });
  }
	render() {
		return (
			<li>
			<span className="w3-button b1-button w3-xlarge" onClick={this.toggle}>
			<i className="fa fa-map-pin w3-left">Change Location</i> 
			<i className={this.state.active ? "fa fa-angle-up w3-right":"fa fa-angle-down w3-right"}></i>
			</span>
      <span className={this.state.active ? "w3-animate-opacity" : "w3-hide"}>
      <Locateme/>
      </span>
			</li>
		);
	}
}

