import React,{Component} from 'react'
import {Link} from 'react-router-dom'
import Cookies from 'universal-cookie';
import Locateme from './../location/Locateme'
import fire from './../../fire';

const cookies = new Cookies();
var db = fire.firestore()
export default class Checkout extends Component{
	constructor(props){
		super(props)
		this.state ={noloc:false}
	}
	minord = () =>{
	   if (cookies.get('seller')) {
	   	var seller = cookies.get('seller');
      db.collection('sellers').doc(seller.id).get()
      .then(res=>{
      	if (res.exists) {
      			var docs = res.data().minord
	   	var dist = cookies.get('seller').dist/1000
     
	   	docs.map((data,id)=>{
	   		
	   		if (dist > data.init && dist <= data.final) {
	   		
	   			this.setState({
	   				min: data.minamount
	   			});
	   		}
	   	})
      	}
      })
	   
	   }else{
	   	this.setState({
	   		noloc:true 
	   	});
	   }
	}

	open =() =>{
		 document.getElementById('changeloc').style.display='block'
	}

	componentDidMount() {
		this.minord()
	}
	render() {
		var chkbtn;
		if (parseInt(this.props.price) < this.state.min) {
			chkbtn = <div className="w3-panel w3-padding w3-yellow b1-align">
			        <h4><i className="fa fa-bell"></i> Order should be of at least of ₹{this.state.min} at 
			        your location. Please Add more items</h4>
			       <Link to='/'> <button className="btn btn-primary">Add More</button></Link></div>
		}
		else{
			chkbtn = <Link to="/checkout"><h2 className="b1-align w3-padding " onClick={e=>{console.log('msg')}}>
				<span className="w3-btn b1-red1 w3-round-large" style={{width : '100%'}} disabled>
				<i className="fa fa-paper-plane">  </i> 
				  Checkout 
				</span></h2> 
				</Link>
		}
		return (
			<div className="col-md-4 ">
			
			<div className="w3-container w3-panel b3-stick ">
			<div className="w3-card">
			<header className="b1-dcolor">
			<h2 className="b1-align">Order Summary</h2>
			</header>
			<div className="w3-container w3-round-large">
              <h2> Total: ₹{this.props.price}</h2>
              
			</div>
			<footer className=" " >
				{this.state.noloc ? <div className="w3-panel w3-yellow w3-padding b1-align">
			       <h4><i className="fa fa-bell"> </i>
			        There is an issue with your location, Please provide your address again.
			         </h4><button className="btn btn-primary" onClick={this.open}> Locate Me </button>
			         </div>:chkbtn}
				</footer>
			</div>
			</div>
			</div>

		);
	}
}