import React,{Component} from 'react'
import fire from './../fire'
import AppSingleorder from './functions/AppSingleorder'
import Cookies from 'universal-cookie';

//Appsingleorder is in functions folder
const cookies = new Cookies();

var db=fire.firestore()
export default class Apporders extends Component {
	constructor(props){
		super(props)  
		this.state ={sid: cookies.get('seller') ? cookies.get('seller').id : 12345,neword : true}
	}
	render() {
		return (
			<div >
             <button className="btn btn-primary" onClick={()=>{this.setState({neword:true})}}>New</button>
             <button className="btn btn-success" style={{marginLeft:'5px'}} onClick={()=>{this.setState({neword:false})}}>Verified</button>
			{this.props.sellerid ? <Activeorders sellerid={this.props.sellerid} neword={this.state.neword} />:''}
			
			</div>
		);
	}
}




class Activeorders extends Component{
	constructor(props){
		super(props)
		this.state= {docs:null}
	}
	getorders = () =>{
		db.collection('newOrders')
		.where('sellerId','==',this.props.sellerid)
			.where('active','==',true)
			.orderBy('orderTime','desc')
		  .onSnapshot(res =>{
           var docs = res.docs;

           this.setState({docs})
          
		},err=>{
			console.log('not permitted',err)
		})
	}
	componentDidMount() {
		this.getorders()
	
	}
	render() {
		var neword = <div><h2 className="b1-aling">New</h2>
		            {this.state.docs?this.state.docs.map((data,id)=>
				<div key={id}>
				<span >
				<AppSingleorder oid={data.id} data={data.data()} new={true} /><br/>
				</span>
				</div>
				)
				:''}</div>

		return (
			<div className="container">
		
		    {neword}
			
			</div>
		);
	}
}

