import React,{Component} from 'react'
import fire from './../../fire';
import Editcart from './Editcart'
import {Link} from  'react-router-dom'

var db= fire.firestore();
export default class Sidecart extends Component{
	constructor(props){
		super(props)
		this.state={
			cartid:[],
			stick : false,
			cart : 0
		}
	}
	snapshot = () =>{
		fire.auth().onAuthStateChanged(user => {
          if (user) {
          	
          	db.collection('udetails').doc(user.uid).onSnapshot(doc => {
          		if (doc.exists) {
              this.setState({
				cart: doc.data().cart,
				uid : user.uid
				});
              this.cartitems();
			
             }
          })
          }
      })
	}
	cartitems = () =>{
		
		fire.auth().onAuthStateChanged(user => {
          if (user) {
   db.collection('cart').where("uid","==",user.uid)
   .where("active","==",true)
   .get()
      	.then(res =>{
      		this.setState({
      				cartid:[] 
      			});
      		if (res.docs.length > 0) {
      			
            res.docs.map((data,id) =>{ 
                        	var cartid = this.state.cartid
                        	if(cartid.includes(data.id)){

                        	}else{
                        		cartid.push(data.id);
                        		this.setState({
                        			cartid 
                        		});
                        		 
                        	}
                        	
         }
      	)
      	}	
      	})
      	.catch(err =>{
      		console.log('error',err)
      	})
      	 }
      })
      
	}
		handleScroll =() =>{
     var navbar = document.getElementById("mysidecart");
     var catmenu = document.getElementById("catmenu");
     var leftmenu = document.getElementById("leftmenu");
       var offset = navbar.offsetTop + catmenu.offsetTop;
       this.setState({
       	offset:offset 
       });
    if (document.body.scrollTop >= offset || document.documentElement.scrollTop >= offset) {
         //navbar.className = navbar.className.replace("just","b2-stick");
         this.setState({
         	stick : true 
         });
         leftmenu.className = leftmenu.className.replace("just","b2-stick");
    } else {
       //navbar.className = navbar.className.replace("b2-stick","just");
       this.setState({
       	stick: false 
       });
       leftmenu.className = leftmenu.className.replace("b2-stick","just");
    }
	}

	componentWillMount() {
		window.addEventListener('scroll', this.handleScroll);
		this.snapshot();
	}
	componentWillUnmount() {
	 window.removeEventListener('scroll', this.handleScroll);
    }
	render() {
		return (
			<div className="col-md-3 b1-hidemobile " >
			<div className=
			{this.state.stick ? " w3-panel w3-white b2-stick " : " w3-panel w3-white  " }
			 id="mysidecart">
			<header className="b1-dcolor">
			<h2 className="b1-align">Items Added</h2>
			</header>
			<div className="b1-autoflow">
			{this.state.cartid.map((cartid,id) =>
				<Singlecart cartid={cartid} key={id} uid={this.state.uid}/>
				)}
                <Link to="/cart">
				<h3 className="b1-align w3-padding " onClick={e=>{console.log('msg')}}>
				<span className="w3-btn b1-red1 w3-round-large" style={{width : '100%'}} disabled>
				Proceed To Cart
				</span></h3>
				</Link>
				<br/>
				<br/>
				<br/>
				<br/>
				</div>
			</div>
			</div>
		);
	}
}

class Singlecart extends Component{
	constructor(props){
		super(props)
		this.state = {}
	}
	removed = (active) =>{
		console.log('removed',active)
	}
	getdetails = () =>{
		db.collection('cart').doc(this.props.cartid).get()
		.then(res =>{
			var data = res.data();
			db.collection('products').doc(data.pid).get()
			.then(ref =>{
			      this.setState({
				         name : ref.data().name,
				         price: ref.data().price,
				         rate : data.rate

			         });	
			})
			
		})
	}
	componentDidMount() {
		this.getdetails()
	}
	render() {
		return (
			<div className="w3-card-2">
			<div className="w3-container">
			<header className="w3-panel">
			<b>{this.state.name}</b>
			</header>
			
			<Editcart cartid={this.props.cartid} rate={this.state.rate} uid={this.props.uid} removed={this.removed}/>

			 
			</div>
			</div>
		);
	}
}
