import React,{Component} from 'react'
import fire from './../fire'
import Categories from './Categories'
import Singleitem from './Singleitem'

var db= fire.firestore();

export default class Allitems extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
   selectcat = e =>{
   	this.setState({
   		cat: e.target.value 
   	});
   }
	
	render() {
		return (
			<div className="container">
			<Categories select={this.selectcat}/>
			{this.state.cat?<Singlecat cat={this.state.cat} sellerid={this.props.sellerid}/>: ''}
			</div>
		);
	}
}

class Singlecat extends Component{
	constructor(props){
		super(props)
		this.state= {productid:[]}
	}
	getitems = (cname) =>{
		 db.collection('newproducts')
			.where('sellerid', '==', this.props.sellerid)
			.where('category','==',cname)
			.onSnapshot(res => {
				let docs = res.size? res.docs : null
			    this.setState({docs})
				
         });
	}
	componentWillReceiveProps(nextProps) {
		
		this.getitems(nextProps.cat)
	}
	componentDidMount() {
		this.getitems(this.props.cat)
	}
	
	render() {
		let {docs,fetched} = this.state 
		return (
			<div className="w3-container">
			<header className="b1-dcolor  b1-align">
			<h3 className="w3-padding">{this.props.cat}</h3>
			</header>
			<div className="w3-container">
				{docs ? 
					docs.map((doc,id)=>
					<Singleitem productid={doc.id} data={doc.data()} key={id}/>
					)
					: null}
		
			</div>
			</div>
		);
	}
}