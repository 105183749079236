import React,{Component} from 'react';
import Locateme from './location/Locateme'
import Cookies from 'universal-cookie';
import { Route, Redirect } from 'react-router'
import Changeloc from './nav/Changeloc'
const cookies = new Cookies();

export default class Home extends Component{
	constructor(props){
		super(props)
	}
   
	componentDidMount() {
		
	}

	takeadd = () =>{
		 document.getElementById('changeloc').style.display='block'
	}
	render() {
		return (
			<div className="b1-over b1-red1 ">
			<Changeloc/>

			<div className="col-md-6 col-md-offset-3 w3-padding b1-align" style={{marginTop:'25vh'}}>
			<br/>
			
			 <h1 className="w3-text-white b1-brand">HeyHungry</h1>
              <button className="w3-button b1-dcolor w3-xlarge" onClick={this.takeadd}>Location</button>
			 <div id="mySidenav" className=" w3-padding  b1-align" style={{bottom : '0'}}>
			 <div id="myOverlay">
			 <h3> Click on the Location Button to find Yummy and delecious food around you...</h3>
			</div>
			</div>
			</div>
             
			</div>
		);
	}
}

