import React,{Component} from 'react'
import fire from './../../fire';
import Editcart from './../body/Editcart'
import price from './Cartsnap'

var db= fire.firestore();
export default class Singlecart extends Component{
	constructor(props){
		super(props)
		this.state = {
			added: true,
			price:0,
			msg : '',
			done:false
		}
	}
	removed = (active) =>{
		this.setState({
			added: false,
		});
		this.props.total(this.state.price*(-1))
	}

	getdetails = () =>{
		db.collection('cart').doc(this.props.cartid).get()
		.then(res =>{
			var data = res.data();
			db.collection('products').doc(data.pid).get()
			.then(ref =>{
			      this.setState({
				         name : ref.data().name,
				         rate : data.rate,
			         });
			         
			})
			
		})

	}

	createmsg = () =>{
		var ord = this.state
		var type = ord.single ? 'S' : ord.half ? 'H' : 'F'
         var msg = ord.name + ' Q-' + ord.quantity + ' R-' + ord.rate + ', '
       
         this.props.itsms(msg,this.props.cartid)
           this.setState({
         	done:true 
         });
	}

	getprice = (cartid) =>{
		db.collection('cart').doc(cartid).onSnapshot(doc=>{
			if (doc.exists) {
				var diff = parseInt(doc.data().total) - parseInt(this.state.price)
				var data = doc.data();
				this.setState({
					price: data.total, 
					quantity: data.quantity,
					single : data.single,
					half : data.half
				});
               this.props.total(diff)
               
			}
		})
		
	}
	
   componentWillUpdate(nextProps, nextState) {
		
		if (nextProps.clicked && !nextState.done) {
			this.createmsg()
		}
	}

	componentDidMount() {
		this.getdetails()
		this.getprice(this.props.cartid)
		
	}
	componentWillUnmount() {
		var unsub = db.collection('cart').onSnapshot(() => {});
      unsub()
	}
	render() {
		const item = this.state.added ?
		         <div className="w3-card">
			<div className="w3-container">
			<header className="w3-panel">
			<b>{this.state.name}</b>
			<b className="w3-right">₹ {this.state.price}</b>

			</header>
			
			<Editcart cartid={this.props.cartid} rate={this.state.rate} uid={this.props.uid} removed={this.removed}/>
			</div>
			</div>: '';
		return (
			<div>
			{item}
			</div>
		);
	}
}