var http = require('http');
var urlencode = require('urlencode');


var sendsms = (sms,mobile) =>{
	
		var msg = urlencode(sms)
			var toNumber = '91'+mobile;
    var username = 'novagame64@gmail.com';
    var hash = '94098f88947d7afdcf99d62015e559b9ebc98e810a6fd7c85fcbd26a29cc22b7'; // The hash key could be found under Help->All Documentation->Your hash key. Alternatively you can use your Textlocal password in plain text.
     var sender = 'HEYHUN';
    var data = 'username=' + username + '&hash=' + hash + '&sender=' + sender + '&numbers=' + toNumber + '&message=' + msg;
    var options = {
     host: 'api.textlocal.in', path: '/send?' + data
    };
     var callback =function (response) {
    
     response.on('data', function (chunk) {
     
    });//the whole response has been recieved, so we just print it out here
      response.on('end', function () {
      
    });
}
http.request(options, callback).end();
	}


export default sendsms;