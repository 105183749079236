import React,{Component} from 'react';
import fire from './../../fire';
import Singlecat from './Singlecat'
import Leftside from './Leftside'
import Carticon from './Carticon'
import Sidecart from './Sidecart'
import Catimg from './Catimg'

var db= fire.firestore();
export default class Category extends Component{
	constructor(props){
		super(props);
		this.state = {
			catnames : [],
			category:[],
			clicked : false,
			catid : 0
		}
	}
	
	close = id =>{
		this.setState({
			clicked : !this.state.clicked,
			catid : id 
		});

	}
	componentDidMount() {
		this.getCount();
		// this.getcat();
		
	}

	change = id =>{
		this.setState({
			clicked : true ,
			catid : id
		});
	} 

	getCount = () =>{
		db.collection('sellers').doc(this.props.sellerid).get()
		.then(res=>{
			var category = res.data().category
			let catnames = category.map(cat => cat.name)
			this.setState({catnames,category})
		})
	}
	getcat = () =>{
		var cat= db.collection('aligncats').doc(this.props.sellerid).get()
		.then(res =>{
			const length = this.state.total;
			var catnames = this.state.catnames;
			for(let i=0; i< length ; i++){
				var cat=`cat${i+1}`;
				catnames.push(res.data()[cat])
			}
		
			this.setState({
				catnames 
			});
			
		})
	}

    
	render() {
		let {catnames,category} = this.state
		return (
			<div className="container-fluid w3-white" >
			<div className="row "  id="catmenu">
			<Leftside data={this.state} close={this.close} change={this.change}/>

			<div className= "col-md-6 w3-pale-blue" style={{padding : '0',marginBottom:'10vh', minHeight : '110vh'}}>
			{category.map((cat,id) =>
			   <div key={id}>
				   <div className={this.state.clicked ? "w3-hide" : "w3-animate-top"} id="allcats">
						<Catimg data={cat} sellerid={this.props.sellerid} key={id} id={id} close={this.close}/>
					</div>	
				   <div 
					className={(this.state.clicked && id == this.state.catid) ? "w3-animate-zoom" : "w3-hide"} >
					<Singlecat name={cat.name} sellerid={this.props.sellerid}  id={id} close={this.close}/>
					</div>
			   </div>
				)}
		
			

			
		
			
			</div>
			<Carticon/>
			<Sidecart/>
			</div>
			<div className="row w3-white" style={{minHeight:'10vh'}}></div>
			</div>
		);
	}
}

