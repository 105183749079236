import React,{Component} from 'react'
import fire from './../../fire';

var db= fire.firestore()

export default class Udetails extends Component{
  constructor(props){
    super(props)
    this.state ={}
  }
  change =e =>{
    this.setState({
    	[e.target.name]: e.target.value ,
    	error : false
    });
  }

  getdetails = () =>{
     fire.auth().onAuthStateChanged(user => {
     	if (user) {
     		var dp = "https://i1.wp.com/www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png?resize=256%2C256&quality=100"
            
     		this.setState({
     			name: user.displayName || '',
     			 pic : user.photoURL || dp,
     			phone : user.phoneNumber || '' ,
     			email : user.email || '',
     			uid : user.uid
     		});
     		
     	}
     })
  }

  submit = () =>{
  	if (this.state.name == "" || this.state.phone == "") {
        this.setState({
  			error: true 
  		});
  	}else{
  		db.collection('udetails').doc(this.state.uid).set({
  			name : this.state.name,
  			photo : this.state.pic,
  			email : this.state.email,
  			phone : this.state.phone,
  			cart : 0
  		}).then(res =>{
  			 document.getElementById('udetails').style.display='none'
  		})
  	}
  }
componentDidMount() {
	this.getdetails()
}

  render() {
  	const data = this.props.data;
    return (
      <div id="udetails" className="b1-udetails " style={{display : 'none'}}>
       <div className=" b1-udcontain" >
       <div className="container" style={{padding : '0'}}>
       <div className="col-md-6 col-md-offset-3 " style={{minHeight : '90vh',padding : '0'}}>
       <header className="b1-dcolor" style={{height : '30vh',top : '0'}}>
       <br/>
         <img src={this.state.pic} className="w3-image w3-circle b1-profile "
         style={{ marginLeft : '40%'}}/>
         <h2 style={{marginLeft : '2vw' }}>Info</h2>
       </header>
       <div className="w3-container w3-white" style={{height : '75vh' , padding : '10%'}}>
       

       <div className="col-xs-3 w3-xlarge w3-padding "><span className="w3-right">Name:</span></div>
       <div className="col-xs-9 w3-padding ">
       <input type="text" className="w3-input w3-border" name="name"
       value={this.state.name }   onChange={this.change}/>
       </div>
       <br/>

        <div className="col-xs-3 w3-xlarge w3-padding "><span className="w3-right">Phone:</span></div>
       <div className="col-xs-9 w3-padding ">
       <input type="text" className="w3-input w3-border " value={this.state.phone  }
        name="phone" onChange={this.change}/>
       </div>
      
      <h2 className="w3-button w3-xlarge w3-round-xxlarge b1-dcolor"
       style={{marginLeft : '40%'}} onClick={this.submit}>
      Continue
      </h2>

      {this.state.error ? 
        <div className="w3-panel w3-card w3-yellow w3-padding" >
        <i className="fa fa-bell">Please fill above details before continuing.</i>
        </div> :'' }
      </div>
      </div>
      </div>
      </div>
      </div>
    );
  }
}