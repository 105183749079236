import React,{Component} from 'react';
import fire from './../fire';

export default class Sellerdetails extends Component{
	componentWillMount() {
		var db = fire.firestore();
		const docref = db.collection('sellers').doc(this.props.sellerid);
		docref.onSnapshot(doc=> {
			if (doc.exists) {
			var data = doc.data();
			this.setState({
				title : data.name, 
				place : data.place ,
				
			});
		  }
		});

	}
	constructor(props){
		super(props);
		this.state = ({
           testid: this.props.testid
		});
	}
	render() {
		return (
			<div>
			  <div className ="container w3-padding w3-card-2">
			    <div className="w3-panel  w3-padding ">
			       <header className="w3-teal w3-large">
			       <h2 className="w3-text-center">Name : {this.state.title}</h2>
			       </header>
			             <div className="w3-container w3-padding w3-flat-wet-asphalt">
			             <div className="col-md-4 w3-padding ">
			              <h3 className="w3-blue w3-padding">Place: {this.state.place}</h3>
			             </div>
			         

                         
			         </div>  
			    </div>
			  </div>
            

             
			</div>
		);
	}
}