import React, { Component } from 'react'
import fire from '../fire'

const db = fire.firestore()
export default class SelectImage extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         
      }
    }
    

    componentDidMount() {
      db.collection('newproducts')
      .where("sellerid", "==", "l9h7CyoG8DO8JKUDkLwT")
      .get()
      .then(res =>{
          const docs = res.size ? res.docs : null
          this.setState({docs})
      }).catch(err => console.log(err))
    }
    
  render() {
    const docs = this.state.docs
    return (
      <div className="row">
         {docs ? docs.map((data,id)=>
            <div className="col-md-4" key={id}>
              <img src={data.data().img} style={{height:200,width:200}}/>
              <h5>{data.data().name}</h5>
            </div>
            ) : null}
      </div>
    )
  }
}
