import React,{Component} from 'react';
import fire from './../../fire';
import auth from './../auth/Auth'
import Editcart from './Editcart'
import {Redirect,Route} from 'react-router-dom'


var db= fire.firestore();
export default  class Addtocart extends Component{
	componentDidMount() {
		const uid = auth()? auth().uid : 0;
		
		if (uid !== 0) {
		this.setState({
			uid: uid,
			log : true
		});
		}
       this.checkadded()
    	
	}
    

	checkadded = () =>{
      fire.auth().onAuthStateChanged(user => {
          if (user) {
     
   db.collection('cart').where("uid","==",user.uid)
   .where("pid","==",this.props.pid)
   .where("active","==",true)
   .where("single","==",this.props.single)
   .where("half","==",this.props.ishalf)
   .limit(1)
   .get()
      	.then(res =>{
      		if (res.docs.length > 0) {
      			this.setState({
              	added: true,
      			cartid: res.docs[0].id
              });

      			db.collection('cart').doc(res.docs[0].id).onSnapshot(data =>{
      			this.setState({
      				added: data.data().active 
      			});
      		}, err => {
        console.log(`Encountered error: ${err}`);
          });
      		
      	}
      		
      	})
      	.catch(err =>{
      		console.log('error',err)
      	})
       }
     })
	}
	
	removed = (active) =>{
		this.setState({
            added: active
          });
	}
	checkAuth = () =>{
		if(this.state.log){
			this.addtocart()
		}
		else{
		document.getElementById('id01').style.display='block'
			
		}
	}
	addtocart = ()=>{
		this.setState({
			adding: true 
		});
		  	
		const half =  this.props.single ? false : this.props.ishalf;
		var data = {uid : this.state.uid,
		            pid : this.props.pid,
		            single: this.props.single,
		            half: half,
		            rate: this.props.price,
		            quantity: this.state.quantity,
		            total : this.props.price,
		            active : true }

		   db.collection('cart').add(data)
		   .then(res => {
		   	var details = db.collection('udetails').doc(this.state.uid)
		   	details.get()
		   	.then(resolve =>{
		   		 if(resolve.exists){
		   		 		var cart = resolve.data().cart? resolve.data().cart+1 : 1;
		   		    details.update({cart: cart})
		   		 }
		   	
		   	})
		   	this.setState({
		   			added: true,
		   			cartid: res.id,
		   			adding:false
		   		});
		 
		   })
		   .catch(err =>{
		   	console.log('error',err)
		   })

	}
	
	constructor(props){
		super(props)
		this.state ={
           quantity : 1,
           log : false,
           ishalf:this.props.ishalf
		}
	}
	render() {
		
		return (
			<div>
			
			{this.state.added ?
			 <Editcart uid={this.state.uid} cartid={this.state.cartid} rate={this.props.price} removed={this.removed}/>
			:<div className="w3-panel ">
			<span onClick={this.checkAuth}
			 className="w3-button b1-dcolor w3-right w3-round-xlarge">
			 {this.state.adding? 
			 	<i className="fa fa-spinner fa-spin"></i>:
			 	<i className="fa fa-cutlery" >  Add</i>} 
			</span>
		       </div>}
			
			</div>
		);
	}
}

