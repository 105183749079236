import React,{Component} from 'react'
import Checkpartner from './functions/Checkpartner'
import fire from './../fire'
import Dashboard from './dashboard'

var db= fire.firestore()
export default class Partnerdash extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}

	Checkpartner = () =>{
    fire.auth().onAuthStateChanged(user =>{
    	if(user){
 db.collection('partners').where("uid","==",user.uid).limit(1).get()
 .then(res =>{
 	if (res.size == 1) {
 		if (res.docs[0].exists) {
 			var sid = res.docs[0].data().sid 
 			this.setState({
 				sid 
 			});
 		}else{
   	window.location= "/"
   }
 	}
    })
   }else{
   	window.location = "/"
   }
})
}
	
   componentWillMount() {
     this.Checkpartner()
   }
	render() {
	
			const sid =  this.state.sid ?  this.state.sid :"l9h7CyoG8DO8JKUDkLwT"
		return (
			<div >
			
			<Partnerbar sid={sid}/>
			</div>
		);
	}
}

class Partnerbar extends Component{
	constructor(props){
		super(props)
		this.state = {}
	}
	render() {
		var bar = this.props.sid ? <Dashboard sid ={this.props.sid}/> : ''
		return (
			<div>
			{bar}

			</div>

		);
	}
}