import React,{Component} from 'react';
import Mobnav from './../nav/mobnav';
import Cartitems from './Cartitems'
import Checkout from './Checkout'
export default class Cartmain extends Component{
	constructor(props){
		super(props)
		this.state ={
			items: true
		}
	}
	remove = () =>{
		
	}
	getprice = (sum) =>{
		this.setState({
			sum: sum 
		});
	}
	getcart =(data) =>{

	}
	

	render() {
		const citems = this.state.items ?
		 <Cartitems remove={this.remove} getprice={this.getprice} getcart={this.getcart}/> : ""
		return (
			<div>
			<Mobnav/>
			<div className="container">
			<br/>
			<br/>
			<br/>
			<div className="col-md-8">
			{citems}
			</div>
			<Checkout price={this.state.sum}/>
			</div>
			</div>
		);
	}
}