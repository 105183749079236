import React,{Component} from 'react'
import fire from './../fire'
import Orditem from './Orditem'

var db=fire.firestore()
export default class Singleorder extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
	verify = () =>{
		db.collection('orders').doc(this.props.oid).update({ process : true})
		.then(res =>{
			console.log('verified')
			this.setState({
			clicked: true 
		  });
		})
	}

	cancel = () =>{
		db.collection('orders').doc(this.props.oid).update({items: false , cancel : true,process : false})
		.then(res =>{this.setState({
			clicked: true 
		});
		 })
	}

	done = () =>{
		db.collection('orders').doc(this.props.oid).update({items: false , placed : true , process:false})
		.then(res =>{this.setState({
			clicked: true 
		});
		 })
	}
 getdetails = () =>{
 	db.collection('udetails').doc(this.props.data.uid).get()
 	.then(res=>{
 		if(res.exists){
             this.setState(res.data());
             db.doc(`udetails/${this.props.data.uid}/address/${this.props.data.addid}`).get()
             .then(res1 =>{
                if (res1.exists) {
                	var address = res1.data()
                	this.setState({
                		door:address.house,
                		faddress : address.f_address,
                		landmark: address.landmark 
                	});
                }
             })
 		}
 	})
 }
 tdiff = () =>{
 	var tdiff =parseInt((Date.now() - this.props.data.time)/60000);
 	this.setState({
 		tdiff 
 	});

 	setInterval(this.calctime, 60000)
 }

 calctime = () =>{
		var tdiff = this.state.tdiff+1;
      this.setState({
      	tdiff 
      });
	}
 componentDidMount() {
	
	 if (this.props.data.uid) {
		 this.getdetails()
	 }
 	this.tdiff()
 }

	render() {
		return (
			<div className={this.state.clicked ? "w3-hide" : ""}>
			<header className="b1-dcolor w3-container">
			<h4 className="w3-right w3-tag w3-teal">
			{this.state.tdiff} min</h4>
			<div className="row">
			<div className="col-xs-3 ">
			
			<h4>{this.state.name}</h4>
			</div>
			<div className="col-xs-3 ">
			<h4>{this.state.phone}
			{this.state.phone1 ? `, ${this.state.phone1}` : ' '}
			</h4>
			</div>
			</div>
			<div className="col-md-3">
			<h4> Total  : {this.props.data.sum} </h4>
			<h4> Payable: {this.props.data.topay} </h4>
			<h4> {this.props.data.cash ? 'Cash-'+this.props.data.change : 'Paytm'} </h4>
			</div>
			<div className="col-md-3">
			 <button className="btn btn-danger" onClick={this.cancel}> Cancel </button>
			{!this.props.data.process? 
			<button className="btn btn-primary" onClick={this.verify} style={{marginLeft : '5px'}}>
			 Verify  </button>
			:<span>
			<button className="btn btn-success" onClick={this.done} style={{marginLeft : '5px'}}>
			 Placed  </button>
			<h4><i className="fa fa-check-square-o w3-text-green"> Verified</i></h4></span>}
			
			</div>

			<div className="col-md-12">
			<h5>{this.state.door},{this.state.landmark},{this.state.faddress}</h5>
			</div>
			
			</header>
			<div className="w3-container w3-card-4">
			
			{this.props.data? this.props.data.cartids.map((data,id)=>
				<Orditem data={data} key={id}/>):''}
			</div>
			{this.props.data.suggest ? <footer className="w3-container b1-red1">
						<h4>Suggestion : {this.props.data.suggest}</h4>
						</footer> : ''}
			</div>
		);
	}
}


