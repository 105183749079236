import * as firebase from 'firebase';
import * as firebaseui from 'firebaseui';
require("firebase/firestore");

  var config = {
   apiKey: "AIzaSyBM0-zVij_JQ9qZlHlueP_Mbe_6C8w1nUI",
    authDomain: "heyhungry-1245.firebaseapp.com",
    databaseURL: "https://heyhungry-1245.firebaseio.com",
    projectId: "heyhungry-1245",
    storageBucket: "heyhungry-1245.appspot.com",
    messagingSenderId: "728998861643"
  };
  var fire = firebase.initializeApp(config);
   var ui = new firebaseui.auth.AuthUI(firebase.auth())
   var db = fire.firestore()
  export default fire;
  export {ui,db}