import React,{Component} from 'react'
import fire from './../../fire';
import Mobnav from './../nav/mobnav';
var db=fire.firestore()

export default class PlaceOrder extends Component{
	constructor(props){
		super(props)
		this.state ={fake:false,
			cartids : []}
	}
	getitems = () =>{
		var orderid = this.props.match.params.id
		db.collection('orders').doc(orderid).get()
		.then(res=>{
			if (res.exists) {
			var data = res.data();
			console.log(data.cartids)
			this.setState({
				cartids: data.cartids 
			});
			}
		})
		.catch(err =>{
			console.log('encountered',err)
			this.setState({
				fake: true 
			});
		})
	}
	componentDidMount() {
		this.getitems()
	}
	render() {
		return (
			<div className="w3-pale-blue">
			<Mobnav/>
			<div style={{marginTop : '70px'}} className="container ">
			<header className="w3-pale-blue w3-padding">
			<p>Your order has beens placed. You will get a confirmation call on the number you hava 
			provided</p>
			</header>
			
			<div className="row">
			
			<div className="col-md-8  ">
			<div className="w3-container w3-white">
			{this.state.cartids.map((data,id)=>
				<Singleitem key={id} data={data}/>)}
			</div>
			</div>
			<div className="col-md-4 b2-align w3-container">
			<div className="b1-line"></div>
			<i className="fa fa-check-circle-o fa-5x w3-text-green">
			<span className="w3-large">Placed</span>
			</i>
             <svg width="300" height="200">
  <polygon points="100,10 40,198 190,78 10,78 160,198" className="b1-svg" />
    </svg>
		
			</div>
			</div>
			</div>
			</div>
		);
	}
}

class Singleitem extends Component{
	constructor(props){
		super(props)
		this.state={}
	}
	getcart = () =>{
		db.collection('cart').doc(this.props.data).get()
		.then(res =>{
			var data = res.data();
			if(res.exists){
              db.collection('products').doc(data.pid).get()
              .then(ref =>{
              	if (ref.exists) {
              		this.setState({
              			name: ref.data().name,
              			qty : data.quantity,
              			total:data.total 
              		});
              	}
              })
              .catch(err =>{

              })
			}
		})
	}
	componentWillMount() {
		this.getcart()
	}
	render() {
		return (
			<div className="w3-panel w3-leftbar w3-border-blue">
			<h4 className="w3-text-teal">{this.state.name}</h4>
			<p><b>QTY :{this.state.qty}</b></p>
			<p><b>TOTAL : ₹{this.state.total}</b></p>
             
			</div>
		);
	}
}