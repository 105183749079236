import React,{Component} from 'react';
import Mobnav from './../nav/mobnav';
import Cartitems from './Cartitems'
import Exdetails from './Exdetails'
import Payment from './Payment'
import Udetails from './Udetails'
import Placeorder from './Placeorder'
import fire from './../../fire';
import Cookies from 'universal-cookie';
import sendsms from './ordfun'

const cookies = new Cookies();
var db = fire.firestore()
export default class Ordermain extends Component{
	constructor(props){
		super(props)
		this.state ={
			clicked: false,
			items: true,
			cash : true,
			change : 0 ,
			suggest:'',
			sid: cookies.get('seller') ? cookies.get('seller').id : 12345
		}
	}
	remove = () =>{
		
	}
	componentWillMount() {
		var sid = cookies.get('seller') ? cookies.get('seller').id : 12345
		this.getseller(sid)
	}
	getmsg = msg =>{
		var total = this.state.discount ? this.state.topay : this.state.sum
		var mode = this.state.cash ? ` C-${this.state.change} ` :' P '
		console.log(total)
		var sms = 'Order 1 ' + this.state.idet +' '+ this.state.addmsg.slice(0,20)+','+msg.slice(0,150) +
		 this.state.suggest.slice(0,20) +mode+ 'total '+total
        var smsg= `Hey ${this.state.uname}, your order of total ${total} is received. You will be contacted soon for the confirmation of the order.`
	    sendsms(smsg,this.state.phone)
	    sendsms(sms,this.state.sphone)
	}
    getudetails = uid =>{
    	db.collection('udetails').doc(uid).get()
    	.then(res=>{
    		if (res.exists) {
    			var data = res.data()
    			var phone = data.phone.slice(-10)
    			var idet = data.name.slice(0,15)+' '+phone
    		
    			this.setState({
    				idet,phone,uname : data.name
    			});
    		}
    	})
    }
    getseller = (sid) =>{
    	db.collection('sellers').doc(sid).onSnapshot(res =>{
    		if (res.exists) {
    			this.setState({
    				sphone:res.data().phone 
    			});
    		}
    	})
    }
 
	validate = () =>{
		if (this.state.uid) {
			this.setState({
				clicked: true 
			});
			this.submit()
		}
			else{
				this.setState({
					warn : true,
					msg : 'Please select a delivery address.' 
				});
			}
	}
	submit = () => {
		 var time = Date.now();
		 var d = this.state
		 var topay = d.discount ? d.topay : d.sum
		  var data ={
			time ,
			items : d.items,
			addid :d.addid,cartids : d.cartids, cash:d.cash ,change: d.change,
			sid: d.sid, sum:d.sum, topay , uid: d.uid 
		      }
       db.collection('orders').add(data)
         .then(res =>{
         		this.setState({
         		orderid: res.id 
         	});
         
         })
         .catch(err =>{console.log('dikkat hai',err)})
	}
	getprice = (sum) =>{
		this.setState({
			sum: sum ,
			topay : this.state.discount ?
			 parseInt(parseInt(sum) - parseInt(parseInt(sum)*parseInt(this.state.discount)/100)) : sum
		});
	}

	payment = (data) =>{
		this.setState({
			cash : data.cash,
			change : !data.cash? 0 : data.change 
		});
		
	}

	getAdd = (data) =>{
		var addmsg = data.house+','+data.landmark+','+data.f_address.slice(0,15)
		this.setState({
			uid: data.uid,
			addid: data.id,
			addmsg,
			warn : false 
		});
		this.getudetails(data.uid)
	}
	suggest = (data) =>{
		this.setState({
			suggest: data
		});
	}
	getcart = (data) =>{
       var cartids = data;
       this.setState({
       	cartids 
       });
	}
	discount = (data) =>{
       var topay = parseInt(this.state.sum) - parseInt(parseInt(this.state.sum)*parseInt(data.discount)/100);

       this.setState({
       	coupon: data.cp_id,
       	topay: parseInt(topay) ,
       	discount: data.discount
       });
	}
	remdis = () =>{
		this.setState({
			discount : '',
			coupon : '',
			topay : '' 
		});
	}
	
	render() {
		const redirect = this.state.orderid ?window.location = `/orders` : ''; 
		const citems = this.state.items ? 
		<Cartitems remove={this.remove} getmsg={this.getmsg} 
		clicked={this.state.clicked} getprice={this.getprice} getcart={this.getcart}/> : ""

		const discount = this.state.discount ?
		     <div>
		     <h4 className="w3-container">
			<span className="w3-left">Discount</span>
			<span className="w3-right">
			₹{parseInt(parseInt(this.state.discount)*parseInt(this.state.sum)/100)}</span>
			</h4>
			<hr className="w3-black"/>
			<h3 className="w3-container">
			<span className="w3-left">Topay</span>
			<span className="w3-right">₹{this.state.topay}</span>
			</h3>
		     </div>
		     :''
		return (
			<div className="w3-light-grey">
			<Mobnav/>
			<div className="container w3-container">
			<br/>
			<br/>
			<br/>
			<div className="row w3-padding">
			<div className="col-md-7 ">
			
			<Exdetails getAdd={this.getAdd}/>

			<Payment pass={this.payment}/>
			</div>
			<div className="col-md-5 w3-white " >
			<div className="w3-white ">
			{citems}
			
			<footer>
			<h3 className="w3-container">
			<span className="w3-left">Subtotal</span>
			<span className="w3-right">₹{this.state.sum}</span>
			</h3>
			{discount}
			</footer>
			{this.state.clicked ? <div className=" w3-overlay " style={{display : 'block'}}>
			<div className="b1-loader"></div>
			</div> : ''}
			{this.state.warn ?
				<div className="w3-panel w3-yellow w3-card w3-padding">
				<i className="fa fa-bell "> {this.state.msg} </i>
				</div>:''
			}
			<Placeorder submit={this.validate} suggest={this.suggest} sum={this.state.sum}
			 discount={this.discount} remdis={this.remdis}/>
			</div>
			</div>
			</div>
			
			</div>
            <br/>
			</div>
		);
	}
}





