import React,{Component} from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Link
} from 'react-router-dom';
//import App from './../App';
import fire from './../fire';
import Home from './../views/Home';
import Menu from './../views/Menu';
import Notavlbl from './../views/nav/Notavlbl';
import Cartmain from './../views/order/Cartmain';
import Ordermain from './../views/order/Ordermain';
import Adressbook from './../views/location/Adressbook';
import OrderPlaced from './../views/order/OrderPlaced';
import Signin from './../views/auth/Signin';
import Orders from './../views/otrack/Orders';
//imports for the partner pages
import Landing from './../views/nav/Landing';//App landing page
//import Signin from './../views/auth/Signin';//login page for admin and partners
//______________________________________________________________________//
import Dashboard from './../editor/dashboard';
import Partnerdash from './../editor/Partnerdash';
import Admindash from './../editor/Admindash';
import Cookies from 'universal-cookie';


const cookies = new Cookies();
var db = fire.firestore();
export default class Web extends Component{
  constructor(props){
    super(props)
    this.state ={seller : cookies.get('seller') ? true :false }
  }

  checkauth = () =>{
      fire.auth().onAuthStateChanged(user => {
         var auth = user ? true : false;
         this.setState({
           auth 
         });
         })
          
  }
  checkseller = () =>{
    var url = cookies.get('seller') ? `/menu/${cookies.get('seller').id}` : "/"
    this.setState({
      url 
    });
  }
  componentWillMount() {
    this.checkseller()
    this.checkauth()
  }
	render() {
		return (
			
   <Router>
    <div>
    <div className="b2-overlay " id="locating" style ={{display:'none'}}>
    <div className='pin bounce'></div>
      <div className='pulse'></div>
      </div>
   {/* <Route exact path="/" render ={() =>this.state.seller ?(
    <Redirect to={this.state.url}/>
  ): (
    <Home/>
  )
  } ></Route>

   <Route exact path="/login" component={Signin} ></Route>
   <Route path="/menu/:id" component={Menu} ></Route>
   <Route path="/not-available" component={Notavlbl} ></Route>
   <Route path="/place/:id" component={OrderPlaced} ></Route>


   <Route path="/cart" component={Cartmain}></Route>
   <Route path="/address-book" component={Adressbook}></Route>


   <Route path="/orders" component={Orders} ></Route>
   
   <Route path="/checkout" component={Ordermain} ></Route> */}
  

   

  <Route exact path="/login" component={Signin} ></Route>
  <Route exact path="/" component={Landing} ></Route>
   <Route path="/partner/dashboard" component={Partnerdash} ></Route>
   <Route path="/admin/dashboard" component={Admindash} ></Route>
    <Route path="/seller/:id" component={Dashboard} ></Route>
   
   </div>
   </Router>

		);
	}
}

