import React,{Component} from 'react';
import Locateme from './../location/Locateme'
import Enteradd from './../location/Enteradd'

export default class Changeloc extends Component{
	constructor(props){
		super(props)
		this.state= {nofetch:false}
	}
	open= () =>{
	   document.getElementById('id01').style.display='block'
	}
	close= () =>{
	   document.getElementById('changeloc').style.display='none'
	}
	componentDidMount() {
		
	}

	fetched = () =>{
		document.getElementById('locating').style.display='none'
		this.setState({
			nofetch:true 
		});
	}
	render() {
		
		return (
			<div>
			
			 <div id="changeloc" className="w3-modal">
          <div className="w3-modal-content w3-animate-zoom w3-card-4" >
         <button className="w3-right b1-dcolor  btn" 
           onClick={this.close}>X</button>
          <header className="b1-dcolor w3-padding b1-align">
           
           <h1 className="b1-brand w3-animate-opacity w3-text-white w3-hover-text-teal"> 
           HeyHungry
            </h1>
          </header>
           <div className="b1-align w3-text-black">
           <br/>
            </div>
            <div className="w3-container "  style={{minHeight : '50vh'}}>
            <div className="b1-align">
            <h4 className="w3-text-black">Enter Your Address </h4>
            <span className="w3-text-black"><Enteradd /></span>
            {this.state.nofetch ? <div className="w3-panel w3-yellow w3-padding w3-card">
            <h4>We are not able to fetch your locatin. Try again or enter your address above</h4></div>:
             <h2 className="b1-brand w3-text-black">Or</h2>}
           
            <span style={{margin:'50%'}} onClick={this.close}  >
            <Locateme fetch = {this.fetched}/>
            </span>
             </div>
             </div>
           <footer className="w3-container b1-dcolor">
                  <br/>
                </footer>
              </div>
            </div>
         </div>
			
		);
	}
}