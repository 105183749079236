import React,{Component} from 'react'
import fire from './../fire'

var db= fire.firestore();

export default class Categories extends Component{
	constructor(props){
		super(props)

		this.state = {
			catid :[]
		}
	}

	getcat = () => {
		db.collection('categories').get()
		.then(res =>{
			
			let docs = res.docs;
			const catid = docs.map(obj => obj.ref.id)
			this.setState({
				catid: catid
			});
			catid.map(qid => {
					this.getDetails(qid);
				})
			console.log(this.state)
		})
		.catch(err=>{
			console.log('not permitted')
		})
	}

	getDetails= (qid) =>{
		var db = fire.firestore();
		var tref = db.collection('categories').doc(qid);
		var tdetail = tref.get()
		              .then(res =>{
		              	this.setState({
		              		[qid]: res.data().name
		              	});
		              })
		          }

	componentWillMount() {
		this.getcat()
		
	}
	render() {
		return (
			<div className="container">
			<div className="col-xs-6">
			<h3 className="w3-right"> Categories: </h3>
			</div>
			<div className="col-xs-6 w3-padding">
			<select className="w3-select w3-border" name="category" onChange={this.props.select}>
               {this.state.catid.map(cid =>
				 <option value={this.state[cid]} key={cid}>{this.state[cid]}</option>
				 )}
  
            </select>
			</div>
			</div>
		);
	}
}
