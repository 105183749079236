import React,{Component} from 'react';
import auth from './Auth';


export default class Loginmodel extends Component{
	constructor(props){
		super(props)
		this.state= {}
	}
	open= () =>{
	   document.getElementById('id01').style.display='block'
	}
	close= () =>{
	   document.getElementById('id01').style.display='none'
	}
	componentDidMount() {
		
	}
	render() {
		const logo =   <img src="https://firebasestorage.googleapis.com/v0/b/heyhungry-1245.appspot.com/o/public%2Flogo%2Fheylogoo2.png?alt=media&token=fc56a494-b4f4-482b-a7bf-e97a1ca28bd0"
      
       className="w3-image b1-logo w3-round-xxlarge" 
       />
		return (
			<div>
			
			 <div id="id01" className="w3-modal">
          <div className="w3-modal-content w3-animate-zoom w3-card-4" >
         <button className="w3-right b1-dcolor  btn" 
           onClick={this.close}>X</button>
          <header className="b1-dcolor w3-padding b1-align">
           
             {logo}
           <h1 className="b1-brand w3-text-white w3-hover-text-teal"> 
           HeyHungry
            </h1>
          </header>
           <div className="b1-align">
           <br/>
            </div>
            <div className="w3-container" id="binova" style={{minHeight : '50vh'}}>
          
             </div>
           <footer className="w3-container b1-dcolor">
                  <br/>
                </footer>
              </div>
            </div>
         </div>
			
		);
	}
}