import React,{Component} from 'react';
import fire from './../../fire'

var db= fire.firestore();

export default class Udetails extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
	getdetails =() =>{
		fire.auth().onAuthStateChanged(user => {
          if (user) {
          	db.collection('udetails').doc(user.uid).get()
          	.then(res =>{
          		if (res.exists) {
          			var data = res.data()
          			console.log('details',data)
          			this.setState({
          				name: data.name,
          				phone: data.phone,
          				uid : user.uid 
          			});
          		}
          	})
          }
      })
	}
	componentDidMount() {
		this.getdetails()
	}
	render() {
		var name = this.state.name ?
		            <div>
		            <p><b className="w3-padding w3-text-dark-grey">
		            <i className="fa fa-user w3-large"> {this.state.name}</i></b>
		             <i className="fa fa-pencil w3-btn b1-dcolor" onClick={this.go}></i></p>
		            </div>
		            :''

		var phone = this.state.phone ?
		            <div>
		            <p><b className="w3-padding w3-text-dark-grey">
		            <i className="fa fa-phone w3-large"> {this.state.phone}</i></b>
		            </p>
		            </div>
		            : 
		            <div className="input-group">
          <input type="text" className="form-control w3-border w3-border-blue" placeholder="Enter Your Phone No." 
          aria-describedby="basic-addon2" maxLength="10" style={{zIndex : '0'}}  onChange={this.change}/>
          <span className="input-group-addon w3-btn w3-pink" id="basic-addon2" onClick={this.check}>
          Add </span>
            </div>
		return (
			<div className=" w3-padding">

			<div className="b1-lcolor">
			<h2 className="w3-text-grey w3-padding"> Details</h2>
			<hr/>
			<div className="col-md-7">
			{name}
			{phone}
			{this.state.phone ? <Extraphone uid={this.state.uid}/> : ''}
			</div>
			</div>
			</div>
		);
	}
}

class Extraphone extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
	change = e =>{
		this.setState({
			no: e.target.value,
			error:false 
		});
	}
    add = () =>{
    	if (this.state.no.length > 9 ) {
    	db.collection('udetails').doc(this.props.uid).update({phone1: this.state.no})
         }else{
         	this.setState({
         		error:true 
         	});
         }
    }
	render() {
		return (
			<div>
			<h4>Add Another Phone Number (Optional)</h4>
			<div className="input-group">
          <input type="text" className="form-control w3-border w3-border-blue" placeholder="Enter Your Phone No." 
          aria-describedby="basic-addon2" maxLength="10" style={{zIndex : '0'}}  onChange={this.change}/>
          <span className="input-group-addon w3-btn w3-pink" id="basic-addon2" onClick={this.add}>
          Add </span>

            </div>

          {this.state.error ?<div className="w3-panel w3-red w3-padding">
           Phone Number must contain 10 digits. </div>: ''}
         
			</div>
		);
	}
}

         