import React,{Component} from 'react';
import fire from './../../fire';
import Cookies from 'universal-cookie';
import {Link} from 'react-router-dom'

var db = fire.firestore()
const cookies = new Cookies();

export default class Placeorder extends Component {
	constructor(props){
		super(props)
		this.state = {
			applied: false
		}
	}
	write = e =>{
		this.props.suggest(e.target.value)
	}
	change = e =>{
		if (this.state.notvalid) {
			this.setState({
				notvalid:false 
			});
		}
		this.setState({
			promo: e.target.value 
		});
	}

	check = () =>{
		this.setState({
			checking: true,
			notvalid: false  
		});
      db.collection('coupons').where("code","==",this.state.promo)
      .where("active","==",true).get()
      .then(res =>{
      	if(res.size > 0){
      		var id =res.docs[0].id
      		db.collection('coupons').doc(id).get()
      		.then(data =>{
      			if (data.data().active) {
      				var data = data.data();
      				data.cp_id = id
      				this.setState({
      					discount : data.discount,
      					applied : true
      				});
      				this.props.discount(data)
      			}
      		})
      	}else{
      		this.setState({
      			checking: false,
      			notvalid: true 
      		});
      	}
      })
	}

	remove = () =>{
		this.setState({
			applied: false,
			discount : 0 ,
			checking : false
		});

		this.props.remdis()
	}

	submit = () =>{
		this.props.submit()
	}
   minord = () =>{
	   if (cookies.get('seller')) {
	   	var docs = cookies.get('seller').minord
	   	var dist = cookies.get('seller').dist/1000
       console.log(dist)
	   	docs.map((data,id)=>{
	   		console.log(data)
	   		if (dist > data.init && dist <= data.final) {
	   		
	   			this.setState({
	   				min: data.minamount
	   			});
	   		}
	   	})
	   }
	}

	componentDidMount() {
		this.minord()
	}
	render() {
		const coupon = this.state.applied ? 
		<div className="w3-card-4 w3-container">
		<h4> <b>{this.state.promo}</b> Applied , you get 
		<b>{this.state.discount}%</b> off on your order.</h4>
		<span className="w3-btn w3-red" onClick={this.remove}>Remove </span>
		<br/>
		<br/>
		</div>
		:<div>
		<div className="input-group">
        <input type="text" className="form-control w3-border w3-border-blue" placeholder="Have Promocode?" 
        aria-describedby="basic-addon2" maxLength="5" style={{zIndex : '0'}}  onChange={this.change}/>
        <span className="input-group-addon w3-btn w3-pink" id="basic-addon2" onClick={this.check}>
          {this.state.checking ? <i className="fa fa-spinner fa-spin" ></i>: 'Apply'} </span>
          </div>
          {this.state.notvalid ? <p className="w3-text-red">
          <b>{this.state.promo}</b> is not valid or expired. Try another one.
          </p>: ''}
          </div>

		return (
			<div className="w3-container">
			{coupon}
			<br/>
			<textarea className="w3-input w3-border w3-border-blue" placeholder="Any Suggestions..."
			onChange={this.write}/>
			<br/>
			{(this.props.sum < this.state.min) ?
			 <div className="w3-panel w3-padding w3-yellow b1-align">
			        <h4><i className="fa fa-bell"></i> Order should be of at least of ₹{this.state.min} at 
			        your location. Please Add more items</h4>
			       <Link to='/'> <button className="btn btn-primary">Add More</button></Link></div>
			       : 
			       <h2 className="b1-align w3-padding " onClick={this.submit}>
				<span className="w3-btn b1-red1 w3-round-large" style={{width : '100%'}} >
				 <span className="b1-shadow">
				  Place Order
				  </span>
				</span></h2>  }
			
			
     </div>
		);
	}
}