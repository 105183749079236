import React,{Component} from 'react'
import fire from './../../fire';
import {Link} from 'react-router-dom'

var db= fire.firestore();
export default class Carticon extends Component{
	constructor(props){
		super(props)
		this.state = {
			log: false,
			cart: 0
		}
	}
	getno = () =>{
		 fire.auth().onAuthStateChanged(user => {
          if (user) {
             db.collection('udetails').doc(user.uid).onSnapshot(doc => {
              if (doc.exists) {
              this.setState({
              	log:true,
              	cart: doc.data().cart
              });
              }
            })
          }
         })
	}
	componentDidMount() {
		this.getno()
	}
	render() {
		var carticon
				if (this.state.cart > 0) {
					carticon=<div>
					<Link to="/cart">
					       <img src="https://firebasestorage.googleapis.com/v0/b/heyhungry-1245.appspot.com/o/public%2Flogo%2F839346_food_512x512.png?alt=media&token=036e2219-5395-425b-aa2f-6db0a421b161"
					       style ={{height : '65px' ,width : '65px'}}
		            	className="w3-image w3-circle w3-animate-bottom b1-fixed " />
		             	<h2 className="b1-tfixed  w3-animate-bottom" >{this.state.cart}</h2>
					      </Link>
					      </div>
				}
				else{
					carticon = ''
				}
			
		return (
			
			<div>
			{carticon}
			</div>
		);
	}
}