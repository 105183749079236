import React,{Component} from 'react';
import fire from './../../fire';

var db = fire.firestore()
export default class Catimg extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}



	close = () =>{
		this.props.close(this.props.id)
	}
	
	render() {
		let {data} = this.props
		let {img,name} = data
		return (
			<div className="w3-card-2 w3-hover-shadow w3-white" onClick={this.close}>
			<img src={img} alt=""
			className="w3-image w3-responsive w3-animate-opacity" />
			
			<h3 className=" b1-align w3-padding" >{name}
			</h3>
			
			</div>
		);
	}
}