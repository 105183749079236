import React,{Component} from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie';
import fire from './../../fire';
import { Route, Redirect } from 'react-router'


var geo = require('node-geo-distance');
var db = fire.firestore()
const cookies = new Cookies();
export default class Selectseller extends Component{
  constructor(props){
    super(props)
    this.state ={dist : 5000}
  }

   getseller = () =>{
     var pos = this.props.pos
        if (pos) {
         var coord1 = {
                      latitude: pos.lat,
                       longitude: pos.lng
                     }
  
           db.collection('sellers')
           .where("lng",">=",pos.lng-0.07)
           .where("lng","<=",pos.lng+0.07)
           .get()
           .then(res =>{
            var i = 0;
            if (res.size >0) {
               for(let doc of res.docs){
               
              db.collection('sellers').doc(doc.id).get()
              .then(result =>{
                this.setState({
                  [doc.id]: result.data() 
                });
                 i += 1;
               var coord2 = {
                      latitude: result.data().lat,
                       longitude: result.data().lng
                     }
                    var getting= geo.vincenty(coord1, coord2, (dist) => {
                    
                      var length = i;
                       var got= false;
                       if (res.size == length) {
                        got = true
                       }
                        if (parseInt(dist) < parseInt(this.state.dist)) {
                          this.setState({
                            id: doc.id,
                            dist: dist,
                            minord: result.data().minord,
                            got
                          });
                          
                        }else{
                          this.setState({
                            got 
                          });
                        }
                     
                       
                         });
              })
            }
            }else{
              this.setState({
                got : true 
              });
            }
       
           })
           
        
    }
    
  }
componentDidMount() {
  this.getseller()
}
render() {
  const geo =this.state.got ? 
  <Selectshop data={this.state} pos={this.props.pos} address={this.props.address ?this.props.address : false} />  : ''
  return (
    <div >
    {geo}
    </div>
  );
 }
}
class Selectshop extends Component{
  constructor(props){
    super(props)
    this.state= {
      done : false,
      address : 'Loading .....'
    }
  }
getcode = (lat,lng) =>{
    
    var fetching =fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}
      &location_type=GEOMETRIC_CENTER&key=AIzaSyBX2Uj5_LqqFYM0pYKNcHyUirjrW-dLRH0`)
   
   
   fetching.then(res =>{
    
   res.json().then(data =>{
    cookies.remove('address')
    cookies.set('address', data.results[0], { path: '/' , maxAge : 15768000 });
    }).catch(err =>{
      console.log(err)
    })
   
   })
   .then(() =>{
     document.getElementById('locating').style.display='none'
    this.setState({
      done: true 
    });
   })
   .catch(err =>{
    this.setState({
      address: err 
    });
   })
  }
 
 setadd = (address) =>{
  cookies.remove('address')
    cookies.set('address', {formatted_address:address}, { path: '/' , maxAge : 15768000 });
    document.getElementById('locating').style.display='none'
    this.setState({
      done: true 
    });
 }
  componentDidMount() {
    const {data} = this.props;
    cookies.remove('seller')
      if (data.id) {
        cookies.set('seller', {id: data.id,minord: data.minord  , dist : data.dist}, { path: '/' , maxAge : 15768000 }) 
      }
    
   
   this.props.address ? this.setadd(this.props.address) :this.getcode(this.props.pos.lat,this.props.pos.lng)
  }
  render() {
    const redirect = this.state.done ?
    this.props.data.id ? window.location = `/menu/${this.props.data.id}` :
    window.location ='/not-available' : ''
   
    return (
      <div>
      <h4 className="b1-align "> {this.state.address}</h4>
     
      </div>
    );
  }
}