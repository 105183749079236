import React,{Component} from 'react'
import fire from './../../fire';
import Singleitem from './Singleitem'

var db = fire.firestore()


export default class Actlist extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
	getlist = () =>{
       db.collection('orders').where("uid","==",this.props.uid)
       .where("items","==",false).orderBy('time')
       .get()
       .then(res =>{
       	if (res.size < 1) {
       		this.setState({
       			empty: true,
       			msg : 'You have not ordered anything yet.' 
       		});
       	}else{
       			
       	this.setState({
       		orderids : res.docs,
            empty: true,
       	    msg : 'Orders History' 
       	});
       
       	}
       })
	}
	componentDidMount() {
		this.getlist()
	}
	render() {
		const orderids = this.state.orderids ? this.state.orderids : []
		return (
			<div >
			{this.state.empty ? 
				<div className=" w3-panel b1-dcolor w3-card b1-align">
				<h3 className="fa fa-info-circle fa-2x"> {this.state.msg} </h3>
				</div> :
			<div className="b1-loader"></div> }
			
			{orderids.reverse().map((data,id)=><span key={id}>
						<Orderitems  oid={data.id}/>
						<br/>
						</span>)}
			</div>
		);
	}
}

class Orderitems extends Component{
	constructor(props){
		super(props)
		this.state ={cartids : []}
	}
	getorder =() =>{
		db.collection('orders').doc(this.props.oid).get()
		.then(res =>{
			if (res.exists) {
				var data = res.data()
				var tdiff =new Date(data.time);
				this.setState({
					cartids: data.cartids,
					time : `${tdiff.getDate()}/${tdiff.getUTCMonth()+1}` ,
					sum : data.sum,
					topay : data.topay,
					cash : data.cash,
					placed: data.placed
				});

			}
		})
	}

	cancel = () =>{
		db.collection('orders').doc(this.props.oid).update({items : false})
		.then(res =>{
			window.location.reload()
		})
	}

	componentDidMount() {
		this.getorder()
	}
	render() {

		return (
           <div className="w3-card">
		    <header className="b1-dcolor w3-container">
			<h5 className="w3-tag w3-teal w3-padding">Date: {this.state.time}</h5>
			<div className="col-md-6">
			<div className="col-xs-6">
              <h4>Total : ₹{this.state.sum}</h4>
			</div>
			<div className="col-xs-6">
              <h4>ToPay : ₹{this.state.topay}</h4>
			</div>
			</div>
			<div className="col-md-6">
			<div className="col-xs-6">
              <h4>Mode : {this.state.cash ? 'Cash' : 'Paytm'}</h4>
			</div>
			<div className="col-xs-6">
			{this.state.placed ? 
				<h4><i className="fa fa-check-square-o w3-text-green"> Placed</i></h4> :
                 <h4><i className="fa fa fa-times-circle-o w3-text-red"> Cancelled</i></h4>
			}
			</div>
			</div>


			<div className=" col-xs-7">
			<h4>Item</h4>
			</div>

			<div className=" col-xs-2 ">
			<h4 className="w3-right">Qnty</h4>
			</div>


			<div className=" col-xs-3">
			<h4 className="w3-right">Price</h4>
			</div>
			</header>
			<div className="w3-container w3-white " style={{padding : '0'}}>
			{this.state.cartids.map((data,id)=>
				<Singleitem key={id} data={data}/>)}
			</div>
			</div>
		);
	}
}
