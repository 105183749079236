import React,{Component} from 'react';
import fire from './../../fire';
import Singlepro from './Singlepro'
var db= fire.firestore();

export default class Singlecat extends Component{
	constructor(props){
		super(props)
	   this.state={
	   	productid :[]
	   }
	}
    componentDidMount() {
		this.getproduct(this.props.name)
		
    }
  
	getproduct = (cname) =>{
		db.collection('newproducts')
		.where('sellerid', '==', this.props.sellerid)
		.where('category','==',cname)
		.where('available','==',true)
		.where('active','==',true)
		.onSnapshot(res =>{
			console.log(res.size)
			let docs = res.size ? res.docs : []
			this.setState({fetched:true,docs})
		})
		// 	menu.get().then(querySnapshot => {
		// 		let docs = querySnapshot.docs;
		// 		const productid = docs.map(obj => obj.ref.id)
		// 		this.setState({
		// 			productid: productid
		// 		});
				
		//  });
		
    }
	close = () =>{
		this.props.close(this.props.id)
	}
	render() {
		const {fetched,docs} = this.state
		return (
			
			<div className="w3-animate-opacity" style={{paddingBottom : '20vh'}}>
			<span >
			{fetched ? docs.map((doc,id)=>
               <Singlepro data={doc.data()} productid={doc.id} key={id}/>
				) : <div id="loading"/>}
				
             </span>
			
				<span className=" b1-dwbtn w3-animate-bottom b1-aling"> 
				<h4 style={{marginLeft : '20vw'}}>
				<span style={{maxWidth : '55vw'}}>
				{this.props.name} </span>
				<button style={{marginLeft : '2vw'}}
				className="btn btn-primary" onClick={this.close}>Close</button>
				</h4>
				</span>
			</div>
			
		);
	}
}


