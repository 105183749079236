import React,{Component} from 'react';


export default class Payment extends Component{
	constructor(props){
		super(props)
		this.state ={
			cash : true,
			change : 0
		}
	}
  
  cash = () =>{
  	this.setState({
  		cash : true,
  		change: this.state.change 
  	});
  	var data = {cash : true , change : this.state.change}
  	this.props.pass(data)
  }
  
   paytm = () =>{
  	this.setState({
  		cash : false,
  		
  	});
  	var data = {cash : false , change : 0}
  	this.props.pass(data)
  }

   handlechange = e=>{
   	this.setState({
         	change: e.target.value 
         });
        	this.props.pass({cash : true , change : e.target.value})
       }
	render() {
		return (
			<div className=" w3-padding">

			<div className="b1-lcolor">
			<h2 className="w3-text-grey w3-padding">Payment Details</h2>
			<hr className="w3-black"/>
			<header className="b1-dcolor b1-align">
			<h3 className="w3-padding"> Pay On Delivery</h3 >
			</header>
			<div className="w3-card-4 w3-container">
			<br/>
			<div className="btn-group btn-group-justified">
        <a  className={this.state.cash ? "btn btn-primary" : "btn w3-border"} onClick={this.cash}>Cash</a>
        <a  className={!this.state.cash ? "btn btn-primary" : "btn w3-border"} onClick={this.paytm}>Paytm</a>

           </div> 
           <br/>
           {this.state.cash ?
         <div>
         <p>If you do not have changes as per the order, please mention it here</p>
         <input type="number" className="w3-input w3-border" onChange={this.handlechange} 
         value={this.state.change>0 ? this.state.change : ''}
         placeholder="Bring me changes of...(e.g ₹500)"/>
         </div>
         :<p>You have to pay via Paytm at the time of delivery.<br/><br/><br/></p>
           }
           <br/>
			</div>
	        </div>
	        
	        </div>
		);
	}
}