import React,{Component} from 'react'
import fire from './../fire'
import Uploadimg from './Uploadimg'

var db= fire.firestore()

export default class Singlepro extends Component{
	constructor(props){
		super(props)
		this.state ={
			data: {},
			editing : false
		}
	}
	toogle = (name) =>{
		let {data} = this.state
		let bool = data[name]
		let upObj ={[name]: !bool}
		console.log('update',upObj)
		db.collection('newproducts').doc(this.props.productid).update(upObj)
		.then(res=>{
			data[name] = !bool
			this.setState({
				data
			});
		})
	}

	toogle1 = () =>{
		let {data} = this.state
		let {veg} = data
		db.collection('newproducts').doc(this.props.productid).update({veg: !veg})
		.then(res=>{
			data.veg = !veg
			this.setState({
				data
			});
		})
	}
   
   editing =() =>{
   	this.setState({
   		editing:!this.state.editing 
   	});
   	console.log('ye hai state ',this.state)
   }
	update = data =>{
		this.setState(data);
		this.editing()
       console.log('data aaya hai ',data)
	}
	getDetails= (pid) =>{
		
		var tref = db.collection('products').doc(pid);
		var tdetail = tref.get()
		              .then(res =>{
		              	var resd= res.data();
		              	this.setState({
		              		name: resd.name ,
		              		single : resd.single,
		              		price : resd.price,
		              		half : resd.half,
		              		full : resd.full,
		              		type : resd.type,
		              		veg : resd.veg,
		              		active: resd.available 
		              	});
		              })
		             
	}
	componentWillReceiveProps(nextProps) {
		// this.getDetails(nextProps.productid)
		this.setState({data:nextProps.data})
	}
	componentDidMount() {
		this.setState({data:this.props.data})
	}
	render() {
		let {data} = this.state
		let {name,active,veg,available,img} = data

		return (
			<div className="w3-panel b1-align  w3-card-2 w3-hover-shadow w3-padding">
			
			<div className="col-sm-5 ">
			<h6 className=" w3-large ">{name} </h6>
			{img && <img src={img} alt="no Image" height={100} width={200}/>}
			</div>
			<div className="col-sm-4">
			<i className={available ? "fa fa-toggle-on w3-text-green fa-2x" : 
			"fa fa-toggle-off w3-text-red fa-2x"} onClick={() =>this.toogle('available')}>
			</i>

			<span style={{margin:8,fontSize:20}}> - available   ||   active - </span>

			<i className={active ? "fa fa-toggle-on w3-text-green fa-2x" : 
			"fa fa-toggle-off w3-text-red fa-2x"} onClick={() =>this.toogle('active')}>
			</i>

			
			
			</div>
			<div className="col-sm-2">
			<i className="fa fa-2x fa-pencil" onClick={this.editing}>
			</i>

			
			
			<i className={veg? "fa fa-toggle-on w3-text-green fa-2x" : 
			"fa fa-toggle-off w3-text-red fa-2x"} onClick={this.toogle1}>
			</i>
			</div>
			<div className="col-sm-3 ">
			{/* <h4>
			{this.state.single ? this.state.price : this.state.half+" / "+this.state.full}
			</h4> */}
			</div>
			<span className={this.state.editing ? "" : "w3-hide"}>
			<Edititem data={data} pid={this.props.productid} update={this.update}/>
			</span>
			</div>
		);
	}
}


class Edititem extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
    change = e =>{
         this.setState({
         [e.target.name]: e.target.value  
         });
    }

    geturl = (url) =>{
    	this.setState({
    		img:url 
    	});
    }

    update = () =>{
		    console.log('UPDATING....')
            db.collection('newproducts').doc(this.props.pid).update(this.state)
            .then(res=>{
            	this.props.update(this.state)
            }).catch(err=>{
            	console.log("not permitted")
            })
	}
	 
	componentDidMount() {
		console.log(this.props.data.price)
	}
	
	 
    componentWillReceiveProps(nextProps) {
    	this.setState({});
    }
	render() {
		const typesArray  = [
			{title: 'Single-Double',types : ['1 Peice','2 Peices']},
			{title: 'Half-Full',types : ['Full','Half','Qtr']},
		]
		const {name,price} = this.props.data
		// const price = this.props.data.single ?
		//                 <div className="col-xs-4">
        //      <input type="number" name="price" value={this.state.price ? this.state.price :this.props.data.price}
        //       className="w3-input w3-border" onChange={this.change}/>
        //      </div>:

        //      <div>
        //       <div className="col-xs-4">
        //      <input type="number" name="half" value={this.state.half ? this.state.half :this.props.data.half}
        //       className="w3-input w3-border" onChange={this.change}/>
        //      </div> 
        //      <div className="col-xs-4">
        //      <input type="number" name="full" value={this.state.full ? this.state.full :this.props.data.full}
        //       className="w3-input w3-border" onChange={this.change}/>
        //      </div>
            //  </div>
		return (
			<div className="w3-container">
            <input type="text" className="w3-input w3-border" name="name"
             onChange={this.change} value={this.state.name ? this.state.name :this.props.data.name}/>
            
             {/* {
				price.map((item,id) => 
				 <div key={id} style={{display:'flex',}}>
					 <span style={{fontWeight:'700'}}>{item.type} : <span style={{fontWeight:'500'}}>{item.price} </span> </span>
			     </div>		 
				)
			 } */}

             <div className="col-xs-4">
            <button className="btn btn-primary" onClick={this.update}> Update </button>
             </div>

             <div className="col-xs-4">
          <Uploadimg geturl={this.geturl} />
             </div>
          
			</div>
		);
	}
}