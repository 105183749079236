import fire from './../../fire';


 
 function auth(){
  
 	var user = fire.auth().currentUser;

if (user) {
 return user;
} else {
 return false;
}
     }
  export default auth;