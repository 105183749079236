import React,{Component} from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie';
import fire from './../../fire';
import { Route, Redirect } from 'react-router'
import Selectseller from './Selectseller'

var geo = require('node-geo-distance');
var db = fire.firestore()
const cookies = new Cookies();


export default class Locateme extends Component{
  constructor(props){
  	super(props)
    this.state = {geocode : false}
  }
  locate = () =>{
     document.getElementById('locating').style.display='block'
   document.getElementById("mySidenav").style.width = "0px";
       document.getElementById("myOverlay").style.display = "none";
        
        setTimeout(this.timedout , 5000)

     if (navigator.geolocation) {
     console.log('located',this.state.islocated)
          navigator.geolocation.getCurrentPosition((position) => {
            var pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            this.setState({
             pos,
             islocated : true
            });

            cookies.remove('position')
            cookies.set('position', pos, { path: '/' , maxAge : 15768000 });
          
          })
        }else{
          console.log('unable to start')
        }
      
  }

  timedout = () =>{
    if (!this.state.geocode) {
      document.getElementById('changeloc').style.display='block'
      this.props.fetch()
     //console.log('nahi ho paya hai tm apna dekh lo')
    }
  }
 
  
  render() {
   
    const locate = this.state.islocated? <Selectseller pos={this.state.pos}/>:''
            
  	return (
    <div>
    
  		 {locate}
       <div className=" b1-align w3-padding">
      <button className="w3-button w3-xlarge w3-padding b1-dcolor w3-round-large" onClick={this.locate}>
    <i className="fa fa-thumb-tack">  Locate Me </i>
      </button>
      </div>
       </div>
  	);
  }
}
 
