import React,{Component} from 'react';
import fire from './../../fire';


var db= fire.firestore();
export default class Editcart extends Component{
	constructor(props){
		super(props)
		this.state ={
			quantity : 0
		}
	}
  removecart = () =>{
    db.collection('cart').doc(this.props.cartid).update({active : false})
    .then(res =>{
         this.props.removed(false)
        var details = db.collection('udetails').doc(this.props.uid)
        details.get()
        .then(resolve =>{
          
          var cart = resolve.exists ? resolve.data().cart-1 : 0;
          details.update({cart: cart >=0 ? cart : 0})
        })
    })
        .catch(err =>{
        console.log('hurry',err)
       })
  }

	getqnty = () =>{
		 db.collection('cart').doc(this.props.cartid).onSnapshot(doc => {
             
              this.setState({
              	quantity: doc.data().quantity
              });

             
          }, err => {
        console.log(`Encountered error: ${err}`);
          });
      }
      changeqty = (qty) =>{
      	db.collection('cart').doc(this.props.cartid).update({quantity : qty,
      		total: qty*this.props.rate})
      	.then(res=>{
      		
      	})
      	.catch(err=>{
      		console.log('wrong',err)
      	})
      }
      incqty = no =>{
      	this.changeqty(this.state.quantity+1)
      }
       decqty = no =>{
        if (this.state.quantity > 1) {
      	this.changeqty(this.state.quantity-1)
      }else{
        this.removecart()
      }
      }
	componentDidMount() {
		this.getqnty()
	}
	render() {
		return (
			<div className="w3-container w3-panel  ">
            <div className=" ">
            <div className="w3-bar ">
            <table className="w3-table w3-left w3-round-xlarge" style={{width:'60px'}}>
            <tbody>
         <tr >
         
          <th className="b1-teal1 w3-button " 
          style={{width:'20px', borderRadius : '50% 0 0 0'}} onClick={this.decqty}>
          <b style={{marginLeft : '-4px'}}>- </b></th>
          <th style={{width:'20px'}} className="w3-animate-zoom w3-light-grey">{this.state.quantity}</th>
          <th className="b1-teal1 w3-button" onClick={this.incqty} 
          style={{width:'20px', borderRadius : '0 0  50% 0'}}><b>+</b></th>
        </tr>
        </tbody>
        </table>
            
			<span className="w3-button b1-red1 w3-round-xlarge  w3-right" onClick={this.removecart}>Remove</span>
			</div>
			</div>
			
			</div>
		);
	}
}

{/* <div className="w3-left ">
             
  <div className="b1-btncontain">
  <span className="b1-qbtn b1-minus w3-left"></span>
  <span className="b1-qbtn b1-plus w3-right"></span>
    <span className="b1-qnty">2</span>
  </div>
  </div>*/}