import React,{Component} from 'react';
import fire from './../fire';
const uuidv4 = require('uuid/v4');


export default class Uploadimg extends Component{
	uploadfile =e => {
   	 var file = e.target.files[0]
   	  var stref = fire.storage().ref('/productsImg/'+uuidv4()+file.name);
   	  var task = stref.put(file);
   	   task.on('state_changed', snap =>{
   	   	var percent = (snap.bytesTransferred/snap.totalBytes)*100;
   	   	this.setState({
   	   		percent: percent 
   	   	});
   	   	console.log(percent)
   	   }, err =>{
   	   	console.log(err);
   	   },()=>{
   	   	var imurl = task.snapshot.downloadURL;
   	   	this.setState({
   	   		image: imurl
   	   	});
   	   	this.props.geturl(imurl)
   	   	console.log(imurl)
   	   })
   	   }

	
	//  constructImg = img =>{
	// 	 Jimp.read(img)
	// 	 	.then(lenna => {
	// 			   console.log(lenna)
	// 	 		    return lenna
	// 	 			.resize(256, 256) // resize
	// 	 			.quality(60) // set JPEG quality
	// 	 			.greyscale() // set greyscale
	// 	 			.write('lena-small-bw.jpg'); // save
	// 	 	})
	// 	 	.catch(err => {
	// 	 		console.error(err);
	// 	 	});
	//  }	  
	constructor(props){
		super(props);
		this.state = ({
			percent : '',
             image : ''
            
		})
	}
	render() {
		var imagecomp ;
		if (this.state.image === '') {
          imagecomp =   <div>
                      <input type="file" className="w3-input" onChange={this.uploadfile}/>
                      <div className="w3-light-grey w3-round">
                    <div className="w3-container w3-round w3-blue w3-text-blue" style={{width : this.state.percent+'%'}}>{this.state.percent}</div>
                    
                   </div>
                      </div>
		}
		else{
			imagecomp = <div>
			            <img src={this.state.image} className="w3-image w3-padding w3-responsive"/>
			            <button className="w3-btn w3-large w3-blue w3-round-xxlarge" 
			            onClick={()=>{this.setState({
			            	image: '',
			            	percent: 0 
			            });}}>Change Image</button>
			            </div>
		}
		return (
			<div>
			 {imagecomp}
			</div>
		);
	}
}