import React,{Component} from 'react'
import fire from './../../fire'
import Slider from 'react-slick'
import Addtocart from './../body/Addtocart'


var db= fire.firestore()

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {}
   
  }
  hclick = id =>{
    this.props.hclick(id)
  }
 
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 400,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 3.2,
      slidesToScroll: 2,
       swipeToSlide: true,
       arrows: true,
      
       responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1.5,
          infinite: true,
          
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1.5,
          initialSlide: 2.1
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
           nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
        }
      }]

      
    };

    const carousel = this.props.data ? this.props.data.map((doc,slide) =>
             <div key={slide} ><Carousel pid={doc.id} data={doc.data()}/></div>
              ): <h2 className="w3-text-white">ho jayega tension naa le</h2>
    return (
       
       <div className="b1-margin" style ={{padding : '0'}}>
        <Slider {...settings}>
          {carousel}
        </Slider>
      </div>
     
    );
  }
}

class Carousel extends Component{
  constructor(props){
    super(props)
  }
  hclick = () =>{
   var catid = "cat" + this.props.no;
   var catdiv = document.getElementById(catid);
   catdiv.className = catdiv.className.replace("w3-hide","")
   this.props.hclick()
  }
  render() {
    return (
      <div className="w3-container b1-caromargin" >
      <div className="w3-card-2 w3-hover-shadow b1-round">
      
      <img src={this.props.data.img ? this.props.data.img : "http://pani-food.com/img/uploads/restaurant-default.png"  }
      className="b1-roundtop w3-image w3-responsive" />
      <div className="w3-container w3-white b1-roundbottom">
      
      <Addtocart single={this.props.data.single} price={this.props.data.price} pid={this.props.pid} ishalf={!this.props.data.single}/>
			
      </div>

      </div>
       <br/>
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const {className, style, onClick} = props
  return (
    <div
      className={className}
      style={{...style, display: 'block',marginTop : '-35px' }}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props
  return (
    <div
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
    ></div>
  );
}