import React,{Component} from 'react'
import fire from './../../fire'
import AppOrditem from './../AppOrderitem'

var db=fire.firestore()
export default class AppSingleorder extends Component{
	constructor(props){
		super(props)
		this.state ={clicked:false,name:'....',phone:'....',cashback:0}
	}
	verify = () =>{
		
		db.collection('newOrders').doc(this.props.oid).update({ confirmed : true})
		.then(res =>{
			console.log('verified')
			this.setState({
			clicked: true 
		  });
		})
	}

	cancel = () =>{
		db.collection('newOrders').doc(this.props.oid).update({active: false , placed : false,})
		.then(res =>{
			if (this.props.data.cashUsed) {
				const cash = this.props.data.cashUsed || 0
				db.collection('udetails').doc(this.props.data.uid)
				.update({refCash:cash+this.state.refCash})
			}
			this.setState({clicked:true})
		 })
	}

	done = () =>{
		db.collection('newOrders').doc(this.props.oid).update({active: false , placed : true })
		.then(res =>{
			if (this.props.data.CbId) {
				const cash = this.state.refCash || 0
				db.collection('udetails').doc(this.props.data.uid)
				.update({refCash:cash+this.state.cashback})
			}
			this.setState({clicked:true})
		 })
	}

 getdetails = (uid) =>{
 	db.collection('udetails').doc(uid).get()
 	.then(res=>{
 		if(res.exists){
             this.setState(res.data());
         }
            
 	})
  }
 tdiff = (orderTime) =>{
 	var tdiff =parseInt((Date.now() - orderTime)/60000);
 	this.setState({
 		tdiff 
 	});

 	setInterval(this.calctime, 60000)
 }

 calctime = () =>{
		var tdiff = this.state.tdiff+1;
      this.setState({
      	tdiff 
      });
	}

 getCashback = ()=>{
 	   db.collection('CbRequest').doc(this.props.data.CbId).get()
 	   .then(res=>{
 	   	if (res.exists) {
 	   		const data = res.data()
 	   		this.setState({cashback: parseInt(data.cashback)})
 	   	}
 	   })
 }	
 componentDidMount() {
 	this.getdetails(this.props.data.uid)
	 
	 if(this.props.data.CbId)  this.getCashback()
 	this.tdiff(this.props.data.orderTime)
 }

 componentWillReceiveProps(nextProps) {
   if (this.props.oid != nextProps.oid) {
   	this.tdiff(nextProps.data.orderTime)
   	this.getdetails(nextProps.data.uid)
   
   }
 }

	render() {
		return (
			<div className={this.state.clicked ? "" : ""}>
			<header className="b1-dcolor w3-container">
			<h4 className="w3-right w3-tag w3-teal">
			{this.state.tdiff} min</h4>
			<div className="row">
			<div className="col-xs-3 ">
			
			<h4>{this.state.name}</h4>
			</div>
			<div className="col-xs-3 ">
			<h4>{this.state.phone}
			{this.state.phone1 ? `, ${this.state.phone1}` : ' '}
			</h4>
			</div>
			</div>
			<div className="col-md-3">
			<h4> Total  : {this.props.data.total} </h4>
			<h4> Payable: {this.props.data.topay} </h4>
			<h4> cashback: {this.state.cashback} </h4>
			<h4> {this.props.data.paymode.cash ? 'Cash-'+this.props.data.paymode.change : 'Paytm'} </h4>
			</div>
			<div className="col-md-3">
			 <button className="btn btn-danger" onClick={this.cancel}> Cancel </button>
			{!this.props.data.confirmed? 
			<button className="btn btn-primary" onClick={this.verify} style={{marginLeft : '5px'}}>
			 Verify  </button>
			:<span>
			<button className="btn btn-success" onClick={this.done} style={{marginLeft : '5px'}}>
			 Placed  </button>
			<h4><i className="fa fa-check-square-o w3-text-green"> Verified</i></h4></span>}
			
			</div>

			<div className="col-md-12">
			<h5>{this.props.data.address.house},{this.props.data.address.landmark},{this.props.data.address.f_address}</h5>
			</div>
			
			</header>
			<div className="w3-container w3-card-4">
			
			{this.props.data? Object.entries(this.props.data.itemsObj).map((data,id)=>
				<AppOrditem data={data[1]} key={id}/>):''}
			</div>
			{this.props.data.suggest ? <footer className="w3-container b1-red1">
						<h4>Suggestion : {this.props.data.suggest.text}</h4>
						</footer> : ''}
			</div>
		);
	}
}


