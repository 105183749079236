import React,{Component} from 'react';
import fire from './../../fire';
import Mobnav from './../nav/mobnav';
import Actlist from './Actlist'
import History from './History'
var db= fire.firestore()
export default class Orders extends Component{
	constructor(props){
		super(props)
		this.state = {}
	}
	componentDidMount() {
		fire.auth().onAuthStateChanged(user => {
          if (user) {
          	this.setState({
          		uid: user.uid 
          	});
          	
          }
      })

	}
	render() {
		const show = this.state.uid ?<Showorder uid={this.state.uid}/> : ''

		return (
			<div>
			<Mobnav/>
			<div className="b1-parent  w3-pale-blue " style={{minHeight : '105vh'}}>
			<div className="container" >
			{show}
			</div>
			</div>
			</div>
		);
	}
}

class Showorder extends Component{
	constructor(props){
		super(props)
		this.state ={active:true}
	}
	activate =() =>{
		this.setState({
			active: true 
		});
	}
	deactivate =() =>{
		this.setState({
			active: false 
		});
	}

	render() {
		return (
			<div >
			
			<div className="b2-dwbtn b1-hidelarge">
			<div className="btn-group btn-group-justified  ">
        <a  className={this.state.active ? "btn btn-primary" : "btn w3-border"}
         onClick={this.activate}>Active Orders</a>
        <a  className={!this.state.active ? "btn btn-primary" : "btn w3-border"}
         onClick={this.deactivate}>Order History</a>

           </div>
           </div>

         <div className="b1-hidemobile">
         <hr/>
         </div>
         <div className="b1-hidemobile">
           <button onClick={this.activate}
            className={this.state.active ? "btn btn-primary" : "btn btn-warning"}> Active Orders </button>
   <button onClick={this.deactivate} style={{marginLeft : '20px'}}
           className={!this.state.active ? "btn btn-primary" : "btn btn-warning"}> Orders History</button>
      </div>
          
		   {this.state.active ? <Actlist uid={this.props.uid} /> :
             <History uid={this.props.uid} />
		   }
		   

			</div>
		);
	}
}



