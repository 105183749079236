import React,{Component} from 'react'
import Checkpartner from './functions/Checkpartner'
import fire from './../fire'
import Adashboard from './Adashboard'

var db= fire.firestore()
export default class Admindash extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}

	Checkpartner = () =>{
    fire.auth().onAuthStateChanged(user =>{
    	if(user){
 db.collection('admins').where("uid","==",user.uid).limit(1).get()
 .then(res =>{
 	if (res.size == 1) {
 		if (res.docs[0].exists) {
 			this.setState({
 				admin:true
 			});
 		}else{
   	window.location= "/"
   }
 	}
    })
   }else{
   	window.location = "/"
   }
})
}
	
   componentWillMount() {
     this.Checkpartner()
   }
	render() {
	
		return (
			<div >
			
			<Partnerbar/>
			</div>
		);
	}
}

class Partnerbar extends Component{
	constructor(props){
		super(props)
		this.state = {all:true}
	}
	all =() =>{this.setState({all:true})}

	go =(id) =>{
      this.setState({
      	sid: id,all : false 
      });
	}
	render() {
		
		return (
			<div>
			<div className="w3-bar w3-blue w3-card w3-padding" 
			style={{position : 'fixed',top :'0',zIndex:'2',left:'0'}}>
			<div className="w3-bar-item w3-right">
			<b>Admin</b>
			</div>
           
            <div className={this.state.all?
             "w3-bar-item w3-button w3-teal" : "w3-bar-item w3-button"} onClick={this.all}>Partners</div>
            
			</div>
			<div className="col-md-10 col-md-offset-1" style={{marginTop:'80px'}}>
           {this.state.all?'':<Adashboard sid ={this.state.sid}/> }

			 <span className={this.state.all  ? '' :'w3-hide'}> 
			<Allsellers go={this.go} /></span>
			</div>

			</div>

		);
	}
}


class Allsellers extends Component{
	constructor(props){
		super(props)
		this.state ={sellers:[]}
	}
	getsellers = () =>{
		db.collection('sellers').get()
		.then(res =>{
			console.log(res.docs)
			this.setState({
				sellers: res.docs, 
			});
		})
	}
	componentWillMount() {
		this.getsellers()
	}
	render() {
		return (
			<div>
			{this.state.sellers.map((data,id)=>
				<Singleseller data={data} go={this.props.go} key={id}/> )}
			</div>
		);
	}
}

class Singleseller extends Component{
	constructor(props){
		super(props)
	}
	go = () =>{
		this.props.go(this.props.data.id)
	}
	render() {
		const doc = this.props.data
		const data= doc.data()
		return (
			<div className="w3-panel w3-card w3-padding">
             {data.name}
             <button className="w3-button w3-blue w3-right" onClick={this.go} >Go</button>
			</div>
		);
	}
}