import React,{Component} from 'react';
import fire from './../../fire';
import Special from './../swipe/Special'


var db= fire.firestore()

export default class Specialcat extends Component{
	constructor(props){
		super(props)
		this.state ={}
	}
	getproduct = () =>{
		db.collection('products').where('sellerid','==',this.props.sellerid)
			.where('category','==','SPECIAL').where('available','==',true).get()
			.then(res =>{
				this.setState({
					docs: res.docs 
				});
			})
	}
	componentDidMount() {
		if (this.props.sellerid) {
			this.getproduct()
		}
		
	}
	render() {
		return (
			<div className="container-fluid  b1-contain" >
			<h3 className="b1-align w3-text-yellow" style={{marginTop : '-10px'}}> 
			HEYHUNGRY SPECIAL </h3>
			{this.state.docs ? <Special data={this.state.docs}/> :
            <div className="b1-loader"></div>
		    }
			</div>
		);
	}
}