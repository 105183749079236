import React,{Component} from 'react'
import fire from './../fire'

var db=fire.firestore()
export default class AppOrditem extends Component{
	
	render() {
		const data = this.props.data
		return (
			<div className="w3-panel">
			<div className="col-md-4"><h4>{data.name}</h4></div>
			<div className="col-md-2"><h4>{data.id?data.id != 1 ? 'Qtr' : 'Half' : 'full'}</h4></div>
			<div className="col-md-3">
			<h4>
			<span className="w3-left">{data.qty}</span>
			<span className="w3-right">{data.amount}</span>
			</h4>
			</div>
			</div>
		);
	}
}