import React, {Component} from 'react';
import Slider from 'react-slick'
 
export default class Slides extends Component {
  constructor(props) {
    super(props)
    this.state = {}
   
  }
  hclick = id =>{
    this.props.hclick(id)
  }
 
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide:0,
       swipeToSlide: true,
       arrows: true,
      
       responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          
        }
      }, {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      }, {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
           nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
        }
      }]

      
    };

    const carousel = this.props.catnames ? this.props.catnames.map((name,slide) =>
             <div key={slide} ><Carousel name={name} no={slide} hclick={this.props.hclick}/></div>
              ): <h2 className="w3-text-white">ho jayega tension naa le</h2>
    return (
       
       <div className="b1-contain">
        <Slider {...settings}>
          {carousel}
        </Slider>
      </div>
     
    );
  }
}


function SampleNextArrow(props) {
  const {className, style, onClick} = props
  return (
    <div
      className={className}
      style={{...style, display: 'block', opacity : '0.5'}}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const {className, style, onClick} = props
  return (
    <div
      className={className}
      style={{...style, display: 'block'}}
      onClick={onClick}
    ></div>
  );
}

class Carousel extends Component{
  constructor(props){
    super(props)
  }
  hclick = () =>{
   var catid = "cat" + this.props.no;
   var catdiv = document.getElementById(catid);
   catdiv.className = catdiv.className.replace("w3-hide","")
   this.props.hclick()
  }
  render() {
    return (
      <div className="w3-container b1-slide">
      <div className="w3-card-4  b1-round">
      
      <img src="https://scontent.fdel1-2.fna.fbcdn.net/v/t1.0-9/28576933_1643572632425753_2620970596581769216_n.jpg?oh=eaad974b7d5a0e93cff834f2cc362f32&oe=5B0CA193"
      className="b1-roundtop w3-image w3-responsive" onClick={e=>{console.log('clicked the image')}}/>
      <div className="w3-container w3-white b1-roundbottom">
      <h4 className="w3-text-dark-grey" >{this.props.name}</h4>
      <span className="b1-align">
      <h3><span className="w3-btn w3-round-xxlarge b1-red1" onClick={this.hclick}>View Menu</span></h3>
      </span>
      </div>

      </div>
       <br/>
      </div>
    );
  }
}