import React,{Component} from 'react'
import fire from './../../fire';
import Editcart from './../body/Editcart'
import Singlecart from './Singlecart'

var db= fire.firestore();
export default class Cartitems extends Component{
	constructor(props){
		super(props)
		this.state={
			cartid:[],
			cart : 0,
			sum : 0,
			loading: true,
			sms : '',
			done: false,
			created: false
		}
	}
    itsms = (msg,cid) =>{
    	var sms = this.state.sms + msg
    	
    	this.setState({
    		[cid]:sms,done:true 
    	});
    	
    }

    createmsg = (ns) =>{
     var sms='';
     this.state.cartid.map((msg,id)=>{
     	sms = sms+ ns[msg]
     })
     this.props.getmsg(sms)
     this.setState({
     	created: true 
     });
    }

  componentWillUpdate(nextProps, nextState) {
  	if (nextState.done && !nextState.created) {
  		this.createmsg(nextState)
  	}
  }

	total = (price) =>{
		
		var sum = parseInt(this.state.sum) + price
		this.setState({
			sum: sum 
		});
		this.props.getprice(sum)
		
	}
	snapshot = () =>{
		fire.auth().onAuthStateChanged(user => {
          if (user) {
          	 this.cartitems();
			
          	db.collection('udetails').doc(user.uid).onSnapshot(doc => {
          		if (doc.exists) {
              this.setState({
				cart: doc.data().cart,
				uid : user.uid
				});
             
             }
          })
          }
      })
	}
	cartitems = () =>{
		
		fire.auth().onAuthStateChanged(user => {
          if (user) {
   db.collection('cart').where("uid","==",user.uid)
   .where("active","==",true)
   .get()
      	.then(res =>{
      		this.setState({
      				cartid:[],
      				sum : 0,
      				loading: false
      			});
      		if (res.docs.length > 0) {
      			this.setState({
      				citem: true 
      			});
            res.docs.map((data,id) =>{ 
                        	var cartid = this.state.cartid
                        	if(cartid.includes(data.id)){

                        	}else{
                        		cartid.push(data.id);
                        		this.setState({
                        			cartid 
                        		});
                        		 
                        		 this.props.getcart(cartid)
                        	}
                        	
         }
      	)
      	}else{
      		this.setState({
      			citem: false 
      		});
      	}	
      	})
      	.catch(err =>{
      		console.log('error',err)
      	})
      	 }
      })
      
	}
	
	componentWillMount() {
		this.props.getprice(this.state.sum)
		this.snapshot();
	
	}


	render() {
		return (
			
			<div className="w3-container w3-panel ">
            {this.state.loading ? <div className="b1-loader"></div> : this.state.citem ?
			<header className="b1-dcolor">
			<h2 className="b1-align">Items Added</h2>
			</header> : 
			<div className="col-md-8 col-md-offset-2 w3-card b1-dcolor b1-align w3-padding">
             <i className="fa fa-info-circle fa-2x"> There are no items in the cart</i>
              </div>
			 }
			
			{ this.state.citem ? this.state.cartid.map((cartid,id) =>
				<Singlecart cartid={cartid} key={id} uid={this.state.uid}
				clicked={this.props.clicked} total={this.total} itsms={this.itsms} />
				) : ''}
				
			</div>
			
		);
	}
}



