import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Web from './route/web';
import registerServiceWorker from './registerServiceWorker';
import { CookiesProvider } from 'react-cookie';
 

ReactDOM.render(<Web />, document.getElementById('root'));
registerServiceWorker();
