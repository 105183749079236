import React,{Component} from 'react'
import fire from './../fire'
import Singleorder from './Singleorder'
import Cookies from 'universal-cookie';


const cookies = new Cookies();

var db=fire.firestore()
export default class History extends Component {
	constructor(props){
		super(props)  
		this.state ={sid: cookies.get('seller') ? cookies.get('seller').id : 12345,neword : true}
	}
	render() {
		return (
			<div >
             <button className="btn btn-primary" onClick={()=>{this.setState({neword:true})}}>Placed</button>
             <button className="btn btn-success" style={{marginLeft:'5px'}} onClick={()=>{this.setState({neword:false})}}>Cancelled</button>
			{this.props.sellerid ? <Activeorders sellerid={this.props.sellerid} neword={this.state.neword} />:''}
			
			</div>
		);
	}
}




class Activeorders extends Component{
	constructor(props){
		super(props)
		this.state= {}
	}
	getorders = () =>{
		db.collection('orders').where('sid','==',this.props.sellerid)
			.where('items','==',false).orderBy('time')
		.onSnapshot(res =>{
           var docs = res.docs;
           docs.reverse().map((data)=>{
           this.setState({
           	[data.id]: data.data()
           });
           })
		},err=>{
			console.log('not permitted',err)
		})
	}
	componentDidMount() {
		this.getorders()
	
	}
	render() {
		var neword = <div><h2 className="b1-aling">Placed</h2>
		            {this.state?Object.entries(this.state).map((data,id)=>
				<div key={id}>
				<span  className={!data[1].placed ? "w3-hide" : ""}>
				<Singleorder oid={data[0]} data={data[1]} /><br/>
				</span>
				</div>
				)
				:''}</div>

	   var process = <div><h2 className="b1-aling">Cancelled</h2>
		            {this.state?Object.entries(this.state).map((data,id)=>
				<div key={id}>
				<span  className={data[1].placed  ? "w3-hide" : ""}>
				<Singleorder oid={data[0]} data={data[1]} />
				<br/>
				</span></div>
				)
				:''}</div>
		return (
			<div className="container">
			{this.props.neword ? neword : process}
			
			
			</div>
		);
	}
}

