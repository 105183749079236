import React,{Component} from 'react'
import fire from './../../fire';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


var db = fire.firestore();
export default class Exdetails extends Component{
	constructor(props){
		super(props)
	}
	getAdd = (data) =>{
		
        this.props.getAdd(data)
	}
	render() {
		return (
			<div className="w3-container ">
			<Address getAdd={this.getAdd}/>
			</div>
		);
	}
}

class Address extends Component{
	constructor(props){
		super(props)
		this.state = {
			selected : false,
			hasadd : false,
			object : []
		}
	}
	
    select = (data) =>{
       
    	this.setState({
    		selected: true,
    		seladd : data 
    	});
    	this.props.getAdd(data)
    }
	getaddress = () =>{
       fire.auth().onAuthStateChanged(user => {
          if (user) {
          	this.setState({
          		uid: user.uid 
          	});
          	db.collection(`udetails/${user.uid}/address`).get()
          	.then(res=>{
          		if (res.size > 0) {
          			var object = this.state.object;
          			object = [];
          			this.setState({
          			object
          		});
          		var docs = res.docs
          		for(let doc of docs){
          			db.doc(`udetails/${user.uid}/address/${doc.id}`).get()
          			.then(ref =>{
          				if (ref.exists) {
          					var ab = ref.data();
          					ab.id = doc.id
          					ab.uid = user.uid
          					object.push(ab)
          					this.setState({
          						object 
          					});
          				}
          				
          			})
          		}
          	}
          	})
          }
      })
	}
  change = () =>{
  	this.getaddress()
  	this.setState({
    		selected: false,
    		seladd : '' 
    	});
  }
	
	componentDidMount() {
		this.getaddress()
	}
	render() {
		const address = this.state.selected ? '' : this.state.object.map((data,id) =>
		    <Singleadd key={id} data= {data} uid={this.state.uid} select={this.select}/>
			 ) 

			 const addnew = this.state.selected ? 
			 <Addselected data={this.state.seladd}  change={this.change}/>
			  : <Newadd uid={this.state.uid} select={this.select}/>
		return (
		<div className=" w3-white">
			<div className="">
			<h2 className="w3-text-grey w3-padding">Delivery Address</h2>
			<hr className="w3-text-black"/>
			{address}
	        </div>
	        <hr/>
	        {addnew}
	        <br/>
	        </div>
		);
	}
}

class Newadd extends Component{
	constructor(props){
		super(props)
		this.state ={
			        house:'',
			        landmark: ''
	                }
	}
	handleform = e =>{
		this.setState({
			[e.target.name]: e.target.value ,
			warn : false
		});
	}
	submit = () =>{
		var data ={
			house : this.state.house,
			landmark:this.state.landmark,
			f_address : cookies.get('address') ? cookies.get('address').formatted_address : ''
		}
		if (data.house == '' && data.landmark == '') {
			this.setState({
				warn : true,
				msg : 'Both fields can not be left blank. ' 
			});
		}else{
			this.setState({
				adding: true 
			});
		db.collection(`udetails/${this.props.uid}/address`).add(data)
		.then(res =>{
			this.setState({
				adding: false 
			});
			db.collection(`udetails/${this.props.uid}/address`).doc(res.id).get()
			.then(doc =>{
				if (doc.exists) {
					var data = doc.data();
					data.id = res.id;
					data.uid = this.props.uid;
					this.props.select(data)
				}
			})
		})

			
		}
	}
   componentDidMount() {
   	
   }
	render() {
		return (
			
			<div className=" b1-padding">
			<div className=" w3-card-2 ">
			<header className="b1-dcolor w3-padding">
			<h4 className="b1-align"><i className="fa fa-map-marker" > </i>
			 Add A New Address</h4>
			</header>
			<div className="w3-container">
			<span className="w3-text-black ">
			<br/>
			<input type="text" value={this.state.house} className="w3-input w3-border" name="house" placeholder="HOUSE/DOOR NO" onChange={this.handleform}/>
			<br/>
			<input type="text" value={this.state.landmark} className="w3-input w3-border" name="landmark" placeholder="LANDMARK" onChange={this.handleform}/>
			<h4><i className="fa fa-map-marker fa-2x w3-text-grey w3-quater w3-left"></i>
			<span className="w3-threequarter">{cookies.get('address').formatted_address}
			</span>
			</h4>
			</span>
			<br/>
			</div>
			{this.state.warn ?
				<div className="w3-panel w3-yellow w3-card w3-padding">
				<i className="fa fa-bell "> {this.state.msg} </i>
				</div>:''
			}
			<footer className="w3-padding ">
			<h2 className="b1-align" onClick={this.submit}>
				<span className="w3-btn b1-red1 w3-round-large" style={{width : '100%'}} >
				<i className="fa fa fa-paperclip w3-wide">  {this.state.adding ? 'ADDING..' : 'ADD'} </i> 
				  
				</span></h2> 
			</footer>
			</div>
		
			</div>
		);
	}
}

class Singleadd extends Component{
	constructor(props){
		super(props)
		this.state ={
			removed : false
		}
	}
	delete = () =>{
        db.doc(`udetails/${this.props.uid}/address/${this.props.data.id}`).delete()
        .then(e =>{
        	this.setState({
        		removed:true 
        	});
        })
	}

	select = () =>{
		this.props.select(this.props.data)
	}
	render() {
		return (
		<div  className={this.state.removed ? "w3-hide" : " b1-padding"}>
			 <div  className="w3-card-4 w3-round-xlarge w3-container">
			 <br/> 
			 <div className=" w3-dropdown-hover w3-right">
			 <i className="fa fa-ellipsis-h  w3-text-grey w3-button">
			 </i>
			 <div className="w3-dropdown-content w3-bar-block w3-border w3-left">
              
               <i  className="fa fa-trash-o w3-bar-item w3-button" onClick={this.delete}> Delete</i>
               
             </div>
			 </div>
			 <p>
			 <b>House/Door: </b>{this.props.data.house}
			 <br/>
			 <b>Landmark: </b>{this.props.data.landmark}
			 <br/>
			 {this.props.data.f_address}
			 <br/></p>
			 <h4 className="b2-align">
			 <span className="w3-btn w3-tag w3-padding w3-round-xlarge" onClick={this.select}>
			 Select
			 </span>
			 </h4>
			 </div>
			 <br/>
			 </div>
			
		);
	}
}

class Addselected extends Component{
	constructor(props){
		super(props)
	}

	
	componentDidMount() {
		
	}
	render() {
		return (
			<div className=" b1-padding">
			<div className=" w3-border w3-border-grey w3-container">
			<br/>
			<span className="w3-right">
			<button className="w3-btn b1-red1" onClick={this.props.change}>Change</button>
			</span>
			<br/>
              <p>
			 <b>House/Door: </b>{this.props.data.house}
			 <br/>
			 <b>Landmark: </b>{this.props.data.landmark}
			 <br/>
			 {this.props.data.f_address}
			 <br/></p>
			</div>
			</div>
		);
	}
}