import React , {Component} from 'react';
import fire from './../fire'
import Sellerdetails from './Sellerdetails';
import Uploadimg from './Uploadimg'
import SelectImage from './SelectImage'

export default class Addproduct extends Component{
	constructor(props) {
	  super(props);
	
	  this.state = {show:true};
	}
	submit=() => {
		 this.setState({show:false})
		 
		 setTimeout(()=>{this.setState({show:true})},100)

	}
	render() {
		return (
			<div>
			{this.state.show ? <Newproduct sellerid={this.props.sellerid} submit={this.submit} /> : ''  }
			</div>
			
		);
	}
}

class Newproduct extends Component{
	selectprice = e =>{
		var value = e.target.value
		this.setState({type:value});
		}
	
	geturl = (url) =>{
		this.setState({
			img:url 
		});
	}
	submit = e =>{
		e.preventDefault()
		var db= fire.firestore();
		db.collection('newproducts').add(this.state)
		.then(res =>{
			console.log(res.id)
			
			this.props.submit()
		})
		.catch(err =>{
			console.log('something went wrong',err)
		})
	}
	takedata = e =>{
		this.setState({
			[e.target.name]: e.target.value 
		});
	}
	veg = () =>{
		this.setState({
			veg: true 
		});
	}
	nonveg= () =>{
		this.setState({
			veg : false 
		});
	}
	constructor(props){
		super(props);
		this.state ={
			name:'',
			veg: true,
			available :true,
			active:true,
			type:0,
			price:[],
			sellerid : this.props.sellerid
		}
	}
	
	render() {
		   const length = this.state.price.length
		      
		return (
			<div className="container w3-padding">
			
			  <div className="row w3-padding">
			     <div className="col-md-6 w3-padding">
                     <h3 className="w3-tag w3-padding w3-center">Add New</h3>
		        <form className="w3-container" onSubmit={this.submit}>

                       <label className="w3-text-blue"><b>Name</b></label>
                  <input className="w3-input w3-border" type="text" name="name" value={this.state.name} onChange={this.takedata} required/>
  
                      <label className="w3-text-blue"><b>Category</b></label>
                      <Categories select={this.takedata}/>
                      
                      <input className="w3-radio" type="radio" name="type"  defaultChecked onChange={this.veg}/>
                     <label>Veg</label>

                     <input className="w3-radio" type="radio" name="type"  onChange={this.nonveg}/>
                      <label>NonVeg</label>
                      <br/>
                      <div className="w3-bar">
                      <input type="number" className="w3-input w3-border" placeholder="Full" name="Full" id={0}
                       onChange ={this.change} />
                      <br/>
                      <input type="number" className="w3-input w3-border" placeholder="Half" name="Half" id={1}
                      onChange ={this.change}  disabled={length > 0 ? false : true} />
                      <br/>
                      <input type="number" className="w3-input w3-border" placeholder="Qtr" name="Qtr" id={2}
                      onChange ={this.change} disabled={length > 1 ? false : true}/>
                     </div>
                    
                     <br/>
                     <Uploadimg geturl={this.geturl} />
                     <br/>
                      <button className="w3-btn w3-blue">Add</button>
 
                   </form>
		    </div>
			     
				 <div className="col-md-6 w3-padding">
				  
				 </div>
			 </div>
			</div>
		);
	}

	change = e =>{
		const price = e.target.value
		const zprice = price - parseInt(price*0.05)
		const obj = {price,zprice,type: e.target.name}
		
		var parray = this.state.price
		parray[e.target.id] = obj
		this.setState({price : parray,type: parray.length })
	}
}



class Categories extends Component{
	componentWillMount() {
		this.getcategory()
		
	}
	getcategory = () =>{
		var db= fire.firestore();
		db.collection('categories').get()
		.then(res =>{
			
			let docs = res.docs;
			const catid = docs.map(obj => obj.ref.id)
			this.setState({
				catid: catid
			});
			catid.map(qid => {
					this.getDetails(qid);
				})
			console.log(this.state)
		})
		.catch(err=>{
			console.log(err)
		})
	}

	getDetails= (qid) =>{
		var db = fire.firestore();
		var tref = db.collection('categories').doc(qid);
		var tdetail = tref.get()
		              .then(res =>{
		              	this.setState({
		              		[qid]: res.data().name
		              	});
		              })
		          }

	constructor(props){
		super(props);
		this.state = {
			catid :[]
		}
	   }
	   
	
	render() {
		return (
			<div>
			<select className="w3-select w3-border" name="category" onChange={this.props.select}>
               {this.state.catid.map(cid =>
				 <option value={this.state[cid]} key={cid}>{this.state[cid]}</option>
				 )}
  
            </select>

			</div>
		);
	}
}


